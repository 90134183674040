import { config, listen_experience_array, player_style } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
import { useContext, useEffect } from "react";
import SvgJsx from "../templateux/svg/svg-jsx";
import { PlayerContext } from "../context/player-context";
import Slider from "react-input-slider";
import Link from "next/link";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { useStopwatch } from "react-timer-hook";
import { padZero } from "@/lib/utils/padZero";
import { PushContext } from "../context/push-context";
import { TribeContext } from "../context/tribe-context";
import PlayerNavUx from "./player-nav-ux";

function PlayerNavLiveNested({ open, set_open, userdata }) {
  const ssr_data_tribe = useContext(TribeContext);

  const { player_state, set_player_state } = useContext(PlayerContext);
  const channel_id = `${ssr_data_tribe?.brand_id}-${config.websockets.listen}${player_state.user_id}`;

  const doFidelity = (listen_experience) => {
    //HIFI CHECK
    if (
      (listen_experience?.toString() == "2" &&
        userdata?.id?.toString() == "0") ||
      userdata?.contributor_level < 1
    ) {
      alert(
        "This live stream quality level is only available for paid contributors."
      );
      return;
    }

    //MID CHECK
    if (
      listen_experience?.toString() == "1" &&
      userdata?.id?.toString() == "0"
    ) {
      alert(
        "This live stream quality level is only available for registered members. Create an account and try again."
      );
      return;
    }

    //Good to continue
    set_player_state((prev) => {
      return {
        ...player_state,
        listen_experience: parseInt(listen_experience),
        file: `${prev?.url}pl-${listen_experience}.m3u8`,
      };
    });
    return;
  };

  const onMount = async (channel, userdata) => {
    // channel.presence.subscribe('enter', function(member) {
    //   channel.presence.get(function(err, data) {
    //   });
    // });

    // channel.presence.subscribe('update', function(member) {
    //   channel.presence.get(function(err, data) {
    //   });
    // });

    // channel.presence.subscribe('leave', function(member) {
    //   channel.presence.get(function(err, data) {});
    // });

    var custom_flair = "";
    var flair_t = -1;
    var flair_b = config.chat.flair.defaultcolorbg;
    var flair_c = config.chat.flair.defaultcolortext;

    channel.presence.update({
      id: userdata?.id,
      user_name: userdata?.user_name,
      avatar_url: userdata?.avatar_url,
      avatar_url_sfw: userdata?.avatar_url_sfw,
      avatar_crdate: userdata?.avatar_crdate,
      bol_nsfw: userdata?.bol_nsfw,
      contributor_level: userdata?.contributor_level,
      //,verified: userdata?.verified
      custom_flair: custom_flair,
      flair_t: flair_t,
      flair_b: flair_b,
      flair_c: flair_c,
      listen: true,
    });
  };

  const onUnmount = (channel) => {
    channel.presence.leave();
    channel.presence.unsubscribe();
    channel.unsubscribe();
  };

  const ably = useContext(PushContext);

  useEffect(() => {
    if (userdata && !userdata.isLoading) {
      onMount(ably?.channels?.get(channel_id), userdata);
    }
  }, [userdata]);

  useEffect(() => {
    return () => {
      onUnmount(ably?.channels?.get(channel_id));
    };
  }, []);

  //TIMER FUNCTIONS
  const startTime = +new Date(player_state?.stream_start_date);
  const now = +new Date(); //now
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(
    stopwatchOffset.getSeconds() + (now - startTime) / 1000
  );
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

  //PLAYER DATA TO SEE IF SELECTED BROADCAST HAS GONE OFFLINE
  const {
    data: playerdata,
    loading: playerdataisLoading,
    error: playerdataisError,
  } = useSWRWrapper(
    player_state?.player_type?.toString() == "live" &&
      !player_state?.isEnded &&
      hasData(player_state?.content_id?.toString()) &&
      player_state?.content_id?.toString() !== "0" &&
      player_state?.content_id?.toString(), //id
    `/api/public/v0/broadcast/status?id=${player_state?.content_id}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    config.profile.stream_interval_host //refreshinterval (0=none)
  );

  useEffect(() => {
    if (!playerdataisLoading && !playerdataisError && playerdata) {
      // console.log("playerdata",playerdata)

      //start the broadcast
      if (
        playerdata?.stream_status?.toString() == "1" &&
        player_state?.broadcast_status?.toString() == "0"
      ) {
        //set state
        set_player_state({
          ...player_state,
          broadcast_status: playerdata?.stream_status,
          isEnded: false,
          isPlaying: true,
        });

        // //start the timer
        if (!isRunning) start();
      }

      //end the broadcast
      if (
        playerdata?.stream_status?.toString() == "0" &&
        player_state?.broadcast_status?.toString() == "1"
      ) {
        //reset state
        set_player_state({
          ...player_state,
          broadcast_status: playerdata?.stream_status,
          isEnded: true,
          isPlaying: false,
        });

        //clear the timer
        if (isRunning) pause();
      }

      //update the broadcast title (if host changed it)
      if (
        playerdata?.broadcast_id?.toString() ==
          player_state?.content_id?.toString() &&
        player_state?.content_table?.toString() == "broadcast" &&
        playerdata?.broadcast_title?.toString() !==
          player_state?.title?.toString()
      ) {
        //reset state
        set_player_state({
          ...player_state,
          title: playerdata?.broadcast_title,
        });
      }
    }
  }, [
    playerdata,
    playerdataisLoading,
    playerdataisError,
    player_state,
    set_player_state,
  ]);

  const formatMonthDay = (this_date) => {
    let vdate = new Date(this_date);

    return `${vdate?.getMonth() + 1}/${vdate?.getDate()}`;
  };

  const togglePlay = () => {
    if (player_state?.broadcast_status == "1") {
      set_player_state((prev) => {
        return {
          ...player_state,
          isPlaying: !prev?.isPlaying,
          file: !prev?.isPlaying
            ? `${prev?.url}pl-${
                prev?.listen_experience || "0"
              }.m3u8?${+new Date()}`
            : "",
        };
      });
    } else {
      alert("The show is currently OFF AIR and cannot be played.");
    }
  };

  return (
    <>
      {player_state.isHide == "0" && (
        <>
          <PlayerNavUx
            type={"live"}
            open={open}
            set_open={set_open}
            visibleText={
              player_state?.broadcast_status?.toString() == "1"
                ? `LIVE`
                : "OFF AIR"
            }
            panelContents={
              <div>
                <div>
                  {/* INFO */}

                  <div className="">
                    Title:
                    <Link
                      href={`/${player_state?.user_name?.toLowerCase()}/chat`}
                      className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0"
                    >
                      {hasData(player_state?.title)
                        ? player_state?.title
                        : `Untitled ${formatMonthDay(
                            player_state?.stream_start_date
                          )} Show`}
                    </Link>
                  </div>
                  <div className="">
                    Host:
                    <Link
                      href={`/${player_state?.user_name?.toLowerCase()}`}
                      className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0"
                    >
                      {player_state?.user_name}
                    </Link>
                  </div>
                  <div className="">Type: Broadcast</div>

                  <div className="flex">
                    Duration:
                    <span className="ml-2">
                      <span>{padZero(hours)}</span>:
                      <span>{padZero(minutes)}</span>:
                      <span>{padZero(seconds)}</span>
                    </span>
                  </div>

                  {/* <div>
                  Current Listeners: {player_state?.current_listeners}
                </div>
                <div>
                  Current Chatters: {player_state?.current_chatters}
                </div> */}

                  {/* STATUS */}
                  <div className="hidden">
                    Status:{" "}
                    {player_state?.isMediaLoaded
                      ? "Loaded"
                      : player_state?.isBuffering
                      ? "Buffering"
                      : "Error"}
                  </div>
                </div>

                <div className="flex items-center content-center">
                  Quality:
                  <select
                    className={`
                    ${
                      player_state?.broadcast_status?.toString() == "1"
                        ? `bg-red-600 text-white`
                        : `bg-gray-500 text-gray-200`
                    }
                    border border-white rounded-md ml-1 text-xs py-0.5 `}
                    onChange={(e) => doFidelity(e.target.value)}
                    value={player_state?.listen_experience}
                  >
                    {listen_experience_array.map((e, index) => {
                      // if (e.premium == 0)
                      return (
                        <option key={index} value={e.value}>
                          {e.text}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* PLAY BUTTONS */}

                {/* LIVE */}
                {player_state?.player_type == "live" && (
                  <div
                    className={`group flex items-center content-center text-center my-5 border-t justify-center pt-4 
              ${player_state?.broadcast_status == "1" ? "cursor-pointer" : ""}

              ${
                player_state?.broadcast_status?.toString() == "1"
                  ? `border-red-900`
                  : `border-gray-600`
              }
              
              `}
                    onClick={() => togglePlay()}
                  >
                    <div className="flex-0  opacity-100 group-hover:opacity-90">
                      <SvgJsx
                        type={"fill"}
                        icon={
                          player_state?.broadcast_status == "0"
                            ? "stop-sm"
                            : player_state?.isPlaying
                            ? "pause-sm"
                            : "play-sm"
                        }
                        className={`h-24 w-24  mx-auto `}
                        title={
                          player_state?.broadcast_status == "0"
                            ? "stop"
                            : player_state?.isPlaying
                            ? "pause"
                            : "play"
                        }
                      />
                    </div>
                    <div className="flex-1 font-bold my-auto text-2xl flex items-center ml-2 opacity-100 group-hover:opacity-90">
                      <div>
                        {player_state?.broadcast_status == "0"
                          ? "OFF AIR"
                          : player_state?.isPlaying
                          ? "Listening"
                          : player_state?.isLoading
                          ? `Loading`
                          : "Stopped"}
                      </div>
                    </div>
                  </div>
                )}

                {/* VOLUME */}
                <div
                  className={`flex items-center content-center border-t pt-4
              
              ${
                player_state?.broadcast_status?.toString() == "1"
                  ? `border-red-900`
                  : `border-gray-600`
              }
            `}
                >
                  <div
                    className={`flex-0 cursor-pointer opacity-100 hover:opacity-75
                          ${player_state?.isMuted ? " animate-pulse" : ""}
                `}
                    onClick={() => {
                      set_player_state({
                        ...player_state,
                        isMuted: !player_state?.isMuted,
                      });
                    }}
                  >
                    <SvgJsx
                      type={"fill"}
                      icon={
                        player_state?.isMuted ? "volume-off-sm" : "volume-up-sm"
                      }
                      className={`h-4 w-4  `}
                      title={player_state?.isMuted ? "Muted" : "Unmuted"}
                    />
                  </div>
                  <div className="flex-1 ml-2 text-sm px-2">
                    <Slider
                      axis="x"
                      x={player_state?.volume_slider}
                      xmax={100}
                      disabled={player_state?.isMuted}
                      onChange={({ x }) => {
                        set_player_state({
                          ...player_state,
                          volume: x / 100,
                          volume_slider: x,
                        });
                        localStorage.setItem("volume_slider", x?.toString());
                      }}
                      styles={player_style(player_state?.isPlaying)}
                    />
                  </div>
                  <div
                    className="flex-0 ml-3 w-8 text-left text-xs cursor-pointer  opacity-100 hover:opacity-75"
                    onClick={() => {
                      set_player_state({
                        ...player_state,
                        volume: 1,
                        volume_slider: 100,
                        isMuted: false,
                      });
                    }}
                  >
                    {player_state?.isMuted
                      ? "0"
                      : Math.round(player_state?.volume * 100)}
                    %
                  </div>
                </div>
              </div>
            }
            days={days}
            seconds={seconds}
            minutes={minutes}
            hours={hours}
            togglePlay={togglePlay}
          />
        </>
      )}
    </>
  );
}

export const PlayerNavLive = PlayerNavLiveNested;
