import { useState } from "react";
import FormInput from "../form-input/form-input";
import { ModalWizardOwner } from "../modal/modal-wizard-owner";

export default function WizardOwner({
  isAdmin,
  bol_admin_user_name,
  set_bol_admin_user_name,
  admin_user_name,
  set_admin_user_name,
  user_id,
  set_user_id,
  submitting,
  set_submitting,
  processing,
  set_processing,
  changes,
  set_changes,
  title = "Post Author",
  description = "",
  userdata,
  owner_name,
  owner_id,
}) {
  //FUTURE TODO: SHOW A DROPDOWN WITH POSSIBLE NAMES
  //FROM STORED PROCEDURE select_user_by_staff_json

  const [original_owner_name, set_original_owner_name] = useState(owner_name);
  const [original_owner_id, set_original_owner_id] = useState(owner_id);
  const [staffdata_active, set_staffdata_active] = useState([
    {
      user_name: owner_name,
      id: owner_id,
      permissions: 2047,
    },
  ]);
  const [isOpen, set_isOpen] = useState(false);
  const closeModal = () => {
    set_isOpen(false);
  };

  return (
    <>
      <div>
        <div className="mt-4 mb-2">
          <div>
            <h3 className="font-bold ">{title}</h3>
          </div>
          <div className="italic">{description}</div>
        </div>

        <div
          className={`rounded-lg flex flex-item mx-1 overflow-hidden border 
                 
                          
                            border-blue-700
                            ${isAdmin && bol_admin_user_name == "0" ? " " : ""}
                    `}
        >
          <div className="flex-1 flex items-center content-center">
            <div
              className={`flex-1  flex items-center content-center 
                                    text-white 
                                    
                                    `}
            >
              {/* STATIC */}
              {bol_admin_user_name == "0" && (
                <div className="flex-1 text-md  flex items-center content-center">
                  <div className="flex-1 my-2 px-2">
                    {staffdata_active !== null &&
                    staffdata_active
                      .map((f) => f?.id?.toString())
                      .indexOf(user_id?.toString()) > -1
                      ? staffdata_active.filter((f, i) => f?.id == user_id)[0]
                          ?.user_name
                      : admin_user_name}
                  </div>

                  {isAdmin && (
                    <div className="flex-0">
                      <div
                        //onClick={() => set_bol_admin_user_name("1")}
                        onClick={() => set_isOpen(true)}
                        className="cursor-pointer text-white bg-blue-600 hover:bg-blue-500 font-bold px-4 py-2 rounded-r-md
                                      hover:opacity-75
                                      w-20 text-center
                                      "
                      >
                        Edit
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* EDITING */}
              {bol_admin_user_name == "1" && (
                <div className="flex-1 font-bold text-xl flex items-center content-center">
                  <div className="flex-1">
                    <FormInput
                      type={"user"}
                      title={"Owner"}
                      description={""}
                      helptip={""}
                      name={"admin_user_name"}
                      placeholder={"Username"}
                      value={admin_user_name}
                      set_value={set_admin_user_name}
                      hidden_value={user_id}
                      set_hidden_value={set_user_id}
                      //submitting
                      submitting={submitting}
                      set_submitting={set_submitting}
                      processing={processing}
                      set_processing={set_processing}
                      set_changes={set_changes}
                      changes={changes}
                    />
                  </div>
                  <div className="flex-0 ">
                    <div
                      onClick={() => set_bol_admin_user_name("0")}
                      className="cursor-pointer text-white bg-blue-600 hover:bg-blue-500 font-bold px-4 py-2 rounded-r-md
                                      hover:opacity-75
                                      w-20 text-center
                                      "
                    >
                      Set
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalWizardOwner
        isOpen={isOpen}
        closeModal={closeModal}
        user_id={user_id}
        set_user_id={set_user_id}
        original_owner_id={original_owner_id}
        original_owner_name={original_owner_name}
        admin_user_name={admin_user_name}
        set_admin_user_name={set_admin_user_name}
        userdata={userdata}
        submitting={submitting}
        set_submitting={set_submitting}
        processing={processing}
        set_processing={set_processing}
        set_changes={set_changes}
        changes={changes}
        staffdata_active={staffdata_active}
        set_staffdata_active={set_staffdata_active}
        isAdmin={isAdmin}
      />
    </>
  );
}
