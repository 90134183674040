import * as React from "react";
import { useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import WizardHeader from "@/components/templateux/wizard/wizard-header";
import isAdminUser from "@/lib/utils/isAdminUser";
import WizardSectionForm from "@/components/templateux/wizard/wizard-section-form";
import WizardOwner from "@/components/templateux/wizard/wizard-owner";
import { sluginput } from "@/lib/utils/slugify";
import WizardPager from "@/components/templateux/wizard/wizard-pager";
import { truncate } from "@/lib/utils/truncate";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { ifNull } from "@/lib/utils/ifNull";
import { config, tablehelptype } from "@/lib/config";
import { validJson } from "@/lib/utils/isJsonValid";
import DropzoneHeader from "@/components/templateux/upload/dropzone-header";
import DropzoneWrapperBlob from "@/components/templateux/upload/dropzone-wrapper-blob";

type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  processing?: number;
  set_processing?: any;
  set_changes?: any;
  changes?: any;
  max_files?: any;

  //soundboard info
  soundboard_id?: any;
  itemdata?: any;
  parentdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?: any;
  soundboardcache?: any;
  set_soundboardcache?: any;
  typevalue?: any;
  set_typevalue?: any;

  //inherited info
  ownerdata?: any;
  userdata?: any;

  navtype?: string;
  helptype?: string;
};

function SoundboardForm({
  //submitting
  submitHandler,
  submitting,
  set_submitting,
  processing,
  set_processing,
  set_changes,
  changes,
  max_files,

  //soundboard info
  soundboard_id,
  itemdata,
  parentdata,
  itemdataisLoading,
  itemdataisError,
  show_graphic,
  form_reset,
  set_form_reset,
  actionText,
  soundboardcache,
  set_soundboardcache,

  //inherited info
  ownerdata,
  userdata,
  navtype,
  helptype,
}: Props) {
  const [ispageloaded, set_ispageloaded] = useState(false);
  useEffect(() => {
    set_ispageloaded(true);
  }, []);

  const [admin_user_name, set_admin_user_name] = useState(
    !itemdataisLoading &&
      !itemdataisError &&
      itemdata &&
      ifNull(itemdata?.user_name, ownerdata?.user_name)
  );
  const [bol_admin_user_name, set_bol_admin_user_name] = useState("0");
  const [user_id, set_user_id] = useState(userdata?.id);

  const [_soundboard_title, set_soundboard_title] = useState(
    ifNull(itemdata?.soundboard_title, "")
  );
  const [_soundboard_description, set_soundboard_description] = useState(
    ifNull(itemdata?.soundboard_description, "")
  );
  const [_soundboard_tags, set_soundboard_tags] = useState(
    ifNull(itemdata?.soundboard_tags, "")
  );
  const [_bol_public, set_bol_public] = useState(
    ifNull(itemdata?.bol_public, "1")
  );
  const [_bol_transcribe, set_bol_transcribe] = useState(
    ifNull(itemdata?.bol_transcribe, "1")
  );
  const [_bol_stack, set_bol_stack] = useState(
    ifNull(itemdata?.bol_stack, "1")
  );

  const [_access_level, set_access_level] = useState(
    ifNull(itemdata?.access_level, "1")
  );
  const [_slug, set_slug] = useState(ifNull(itemdata?.slug, ""));
  const [_soundboard_json, set_soundboard_json] = useState(
    ifNull(itemdata?.soundboard_json, "[]")
  );

  const [_thumb_json, set_thumb_json] = useState(
    ifNull(itemdata?.thumb_json, "[]")
  );
  const [_color_text, set_color_text] = useState(
    ifNull(itemdata?.color_text, "")
  );
  const [_color_bg, set_color_bg] = useState(ifNull(itemdata?.color_bg, ""));

  const [files, set_files] = useState([]);
  const [total_files, set_total_files] = useState(0);
  const [track_files, set_track_files] = useState(0);
  const [processing_soundboard, set_processing_soundboard] = useState(false);

  useEffect(() => {
    if (
      form_reset == 1 &&
      hasData(soundboard_id) &&
      soundboard_id.toString() !== "0" &&
      !itemdataisLoading &&
      !itemdataisError
    ) {
      set_admin_user_name(
        hasData(itemdata?.user_name) ? itemdata?.user_name : userdata?.user_name
      );

      set_soundboard_title(
        hasData(itemdata?.soundboard_title) ? itemdata?.soundboard_title : ""
      );
      set_soundboard_description(
        hasData(itemdata?.soundboard_description)
          ? itemdata?.soundboard_description
          : ""
      );
      set_soundboard_tags(
        hasData(itemdata?.soundboard_tags) ? itemdata?.soundboard_tags : ""
      );

      set_bol_public(
        hasData(itemdata?.bol_public) ? itemdata?.bol_public : "1"
      );
      set_bol_transcribe(
        hasData(itemdata?.bol_transcribe) ? itemdata?.bol_transcribe : "1"
      );
      set_bol_stack(hasData(itemdata?.bol_stack) ? itemdata?.bol_stack : "1");

      set_access_level(
        hasData(itemdata?.access_level) ? itemdata?.access_level : "1"
      );

      set_slug(hasData(itemdata?.slug) ? itemdata?.slug : "");

      set_soundboard_json(
        hasData(itemdata?.soundboard_json) ? itemdata?.soundboard_json : "[]"
      );

      set_form_reset(0); //make sure we dont remove the data again now that it's filled in.
    }
  }, [
    itemdata,
    itemdataisLoading,
    itemdataisError,
    soundboard_id,
    changes,
    form_reset,
    set_form_reset,
    userdata,
  ]);

  const { isConfirmed } = useConfirm();

  async function reset_soundboard() {
    if (JSON.parse(_soundboard_json)?.length > 0) {
      const confirmed = await isConfirmed(
        "Changing your soundboard will reset everything. Are you sure?"
      );
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
      if (confirmed) {
        set_soundboard_json("[]");
      }
    }
  }

  function slugformat(value) {
    if (value?.length > 0) {
      set_slug(
        truncate(
          sluginput(soundboard_id, value, value).replace(
            `${soundboard_id}-`,
            ""
          ),
          25,
          ""
        )
          ?.replace(`Untitled`, "")
          ?.toLowerCase()
      );
    } else {
      set_slug("");
    }
  }

  const [wizard_id, set_wizard_id] = useState(0);
  const wizard_array = [
    {
      title: "info",
      description: "",
      short: "info",
      fields: [
        {
          type: "text",
          title: "Title",
          description: "",
          helptip: "",
          name: "soundboard_title",
          placeholder: "",
          value: _soundboard_title,
          set_value: set_soundboard_title,
          meta: { maxChars: 65 },
        },

        {
          type: "textarea-autosize",
          title: "Soundboard Description",
          description: "",
          helptip: "",
          name: "soundboard_description",
          placeholder: "",
          value: _soundboard_description,
          set_value: set_soundboard_description,
          meta: { minRows: 2, maxRows: 10 },
        },
        {
          type: "text",
          title: "Soundboard Tags",
          description: "separate tags with commas",
          helptip: "",
          name: "soundboard_tags",
          placeholder: "",
          value: _soundboard_tags,
          set_value: set_soundboard_tags,
        },
        {
          type: "color",
          title: "Set a Background Color for the Overview",
          description: "",
          helptip: "",
          name: "color_bg",
          placeholder: "",
          value: _color_bg,
          set_value: set_color_bg,
        },
      ],
    },
    {
      title: "advanced",
      description: "",
      short: "advanced",
      fields: [
        {
          type: "toggle",
          title:
            "Allow multiple sounds to be played simultaneously by default?",
          description: "",
          helptip:
            "This only impacts the default state of the soundboard. Users can override this.",
          name: "bol_stack",
          placeholder: "",
          value: _bol_stack,
          set_value: set_bol_stack,
        },
        {
          type: "toggle",
          title: "Allow sounds to be transcribed?",
          description: "",
          helptip: "This helps your sounds be found in search results",
          name: "bol_transcribe",
          placeholder: "",
          value: _bol_transcribe,
          set_value: set_bol_transcribe,
        },

        // {
        //   type:'select'
        //   ,title:'Who can use this soundboard?'
        //   ,description:''
        //   ,helptip:''
        //   ,name:'access_level'
        //   ,placeholder:''
        //   ,options:[
        //     {
        //       text:'Everyone'
        //       ,value: '0'
        //     },
        //     {
        //       text:'Registered Members'
        //       ,value: '1'
        //     },
        //     {
        //       text:'Contributors Only'
        //       ,value: '2'
        //     },
        //   ]
        //   ,value:_access_level
        //   ,set_value:set_access_level
        // },
        // {
        //   type:'text'
        //   ,title:'Customize your URL Ending'
        //   ,description:<div className='text-xs'>{ssr_data_tribe?.server_website}/{admin_user_name}/soundboard/{(soundboard_id?.toString()=="0" ? "42069" : soundboard_id?.toString())}-<span className='text-blue-400'>{sluginput((soundboard_id==0 ? 42069 : soundboard_id),_slug,_soundboard_title)}</span></div>
        //   ,helptip:''
        //   ,name:'slug'
        //   ,placeholder:`${sluginput(soundboard_id,_slug,_soundboard_title).replace(`${soundboard_id}-`,'')}`
        //   ,value:_slug
        //   ,set_value:slugformat
        //   ,meta:{maxChars:25}
        // },

        {
          type: "selectoptions",
          title: "Who can see this soundboard?",
          description: "",
          helptip: "",
          name: "bol_public",
          placeholder: "",
          value: _bol_public,
          set_value: set_bol_public,
          options: [
            {
              text: "Private (only me)",
              value: "0",
            },
            {
              text: "Public (everyone)",
              value: "1",
            },
          ],
        },
      ],
    },
  ];

  const [isAdmin, set_isAdmin] = useState(
    isAdminUser(
      ownerdata?.id?.toString(), //user_id
      ownerdata?.bol_staff_help, //bol_staff_help
      ownerdata?.staff_json, //staff_json
      ownerdata?.bol_admin_help, //bol_admin_help
      userdata?.id?.toString(), //my_id
      userdata?.bol_admin?.toString(), //global_admin
      tablehelptype("soundboard")
    )
  );

  function handleSubmit(e) {
    e.preventDefault();

    const soundboard_data = {
      soundboard_id: soundboard_id,
      soundboard_parent_id: parentdata?.soundboard_id,
      soundboard_title: _soundboard_title,
      soundboard_description: _soundboard_description,
      soundboard_tags: _soundboard_tags,
      bol_public: _bol_public,
      bol_transcribe: _bol_transcribe,
      bol_stack: _bol_stack,
      access_level: _access_level,
      slug: hasData(_slug)
        ? _slug
        : `${sluginput(soundboard_id, _slug, _soundboard_title).replace(
            `${soundboard_id}-`,
            ""
          )}`,
      //, soundboard_json: _soundboard_json
      thumb_json: _thumb_json,
      color_text: _color_text,
      color_bg: _color_bg,
      admin_help_user_id: user_id,
      set_submitting,
    };

    submitHandler(
      soundboard_data,
      `/api/private/soundboard/update`,
      "POST",
      {
        "Content-Type": "application/json",
      },
      "Your changes were saved"
    );
    set_changes(false);
    let cachedate = +new Date();
    set_soundboardcache(cachedate?.toString());
  }

  return (
    <>
      {/* info COMPONENT */}
      <div className="w-full">
        <WizardHeader
          wizard_array={wizard_array}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          disable_mobileview={true}
        />

        {/* FORM */}
        <div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <WizardSectionForm
              section_id={0}
              wizard_id={wizard_id}
              set_wizard_id={set_wizard_id}
              wizard_array={wizard_array}
              submitting={submitting}
              set_submitting={set_submitting}
              processing={processing}
              set_processing={set_processing}
              set_changes={set_changes}
              changes={changes}
            >
              <>
                <DropzoneHeader
                  url={validJson(_thumb_json, [])[0]?.url}
                  set_json={set_thumb_json}
                  empty_value={"[]"}
                  type="image"
                  title="or set a background image"
                  processing={processing_soundboard}
                  set_processing={set_processing_soundboard}
                  generate_prompt={`${config.upload.image.generation_prompt}${_soundboard_title} ${_soundboard_description} ${_soundboard_tags}}`}
                />
                <DropzoneWrapperBlob
                  allowed_files={["image"]}
                  file_type={1} //image - see the map in lib/config/postobject
                  api_url={`/api/private/upload/file?update=soundboardimage&type=image&origin=url&cid=${soundboard_id}`}
                  max_size={config.upload.image.max_size} //2.5mb
                  max_files={config.upload.image.max_files}
                  confirm_upload={false}
                  onUploadSingle={async (data) => {
                    set_thumb_json((prev) => {
                      let new_array = JSON.stringify([
                        JSON.parse(data?.metadata_json),
                      ]);
                      set_color_text(`#ffffff`);
                      set_changes(true);
                      set_processing_soundboard(false);
                      return new_array;
                    });
                  }}
                  files={files}
                  set_files={set_files}
                  total_files={total_files}
                  set_total_files={set_total_files}
                  track_files={track_files}
                  set_track_files={set_track_files}
                  changes={changes}
                  set_changes={set_changes}
                  processing={processing_soundboard}
                  set_processing={set_processing_soundboard}
                  swap_preview={true}
                  preview_url={validJson(_thumb_json, [])[0]?.url}
                  preview_json={validJson(_thumb_json, [])[0]}
                  preview_width={validJson(_thumb_json, [])[0]?.width}
                  preview_height={validJson(_thumb_json, [])[0]?.height}
                />
              </>
            </WizardSectionForm>

            <WizardSectionForm
              section_id={1}
              wizard_id={wizard_id}
              set_wizard_id={set_wizard_id}
              wizard_array={wizard_array}
              submitting={submitting}
              set_submitting={set_submitting}
              processing={processing}
              set_processing={set_processing}
              set_changes={set_changes}
              changes={changes}
            >
              <></>
            </WizardSectionForm>

            <WizardSectionForm
              section_id={2}
              wizard_id={wizard_id}
              set_wizard_id={set_wizard_id}
              wizard_array={wizard_array}
              submitting={submitting}
              set_submitting={set_submitting}
              processing={processing}
              set_processing={set_processing}
              set_changes={set_changes}
              changes={changes}
            >
              <WizardOwner
                isAdmin={isAdmin}
                bol_admin_user_name={bol_admin_user_name}
                set_bol_admin_user_name={set_bol_admin_user_name}
                admin_user_name={admin_user_name}
                set_admin_user_name={set_admin_user_name}
                user_id={user_id}
                set_user_id={set_user_id}
                submitting={submitting}
                set_submitting={set_submitting}
                processing={processing}
                set_processing={set_processing}
                changes={changes}
                set_changes={set_changes}
                title={`Set a Soundboard Owner`}
                description={``}
                userdata={userdata}
                owner_name={itemdata?.user_name}
                owner_id={itemdata?.user_id}
              />
            </WizardSectionForm>

            <WizardPager
              wizard_id={wizard_id}
              set_wizard_id={set_wizard_id}
              submitting={submitting}
              changes={changes}
              actionText={actionText}
              wizard_array={wizard_array}
            />
          </form>
        </div>
        {/* SET referral FORM */}
      </div>
      {/* // referral SET COMPONENT */}
    </>
  );
}

export default SoundboardForm;
