import { playbackRate_array, player_style } from "@/lib/config";
import { countUpBySeconds } from "@/lib/utils/countUp";
import { hasData } from "@/lib/utils/hasData";
import { useContext } from "react";
import SvgJsx from "../templateux/svg/svg-jsx";
import { PlayerContext } from "../context/player-context";
import Slider from "react-input-slider";
import { timerFormat } from "@/lib/utils/timerFormat";
import Link from "next/link";
import { slugify } from "@/lib/utils/slugify";
import PlayerNavUx from "./player-nav-ux";
import { useStopwatch } from "react-timer-hook";

export default function PlayerNavFile({ open, set_open }) {
  const { player_state, set_player_state } = useContext(PlayerContext);

  //TIMER FUNCTIONS
  const startTime = +new Date(player_state?.stream_start_date);
  const now = +new Date(); //now
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(
    stopwatchOffset.getSeconds() + (now - startTime) / 1000
  );
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

  const doSeek = (seekto, x) => {
    player_state?.playerRef?.current?.seekTo(seekto, "seconds");
    set_player_state({
      ...player_state,
      seek: seekto,
      seek_ratio: x / 100,
      seek_slider: x,
      isEnded: seekto >= player_state.duration,
    });

    return;
  };

  const doSeekbyRatio = (x) => {
    const seekto = Math.round((x / 100) * player_state?.duration);
    doSeek(seekto, x);
    return;
  };

  const doSeekbySeconds = (seconds) => {
    const ratio = (seconds * 100) / player_state?.duration;
    doSeek(seconds, ratio);
    return;
  };

  const doPlayback = (speed) => {
    set_player_state({ ...player_state, playbackRate: speed });
    return;
  };

  const formatMonthDay = (this_date) => {
    let vdate = new Date(this_date);

    return `${vdate?.getMonth() + 1}/${vdate?.getDate()}`;
  };

  const togglePlay = () => {
    set_player_state({
      ...player_state,
      isPlaying: !player_state?.isPlaying,
    });
  };

  return (
    <>
      {hasData(player_state.file) && (
        <>
          <PlayerNavUx
            type={"file"}
            open={open}
            set_open={set_open}
            visibleText={timerFormat(
              countUpBySeconds(Math.round(player_state.seek))
            )}
            panelContents={
              <div>
                <div>
                  {/* INFO */}

                  <div className="">
                    Title:{" "}
                    <Link
                      href={`/${player_state?.user_name?.toLowerCase()}/posts/${slugify(
                        player_state?.post_id,
                        player_state?.title?.toLowerCase()
                      )}`}
                      className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0"
                    >
                      {hasData(player_state?.title)
                        ? player_state?.title
                        : `Untitled ${formatMonthDay(
                            player_state?.stream_start_date
                          )} Show`}
                    </Link>
                  </div>
                  <div className="">
                    Host:
                    <Link
                      href={`/${player_state?.user_name?.toLowerCase()}`}
                      className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0"
                    >
                      {player_state?.user_name}
                    </Link>
                  </div>
                  <div className="">
                    Type:{" "}
                    {{
                      2: "Upload",
                      5: "Showreel",
                    }[player_state?.post_type?.toString()] || "Upload"}
                  </div>

                  <div className="">
                    Duration:{" "}
                    {timerFormat(
                      countUpBySeconds(Math.round(player_state?.duration))
                    )}
                  </div>
                  <div className="">
                    Remaining:{" "}
                    {timerFormat(
                      countUpBySeconds(
                        Math.round(player_state?.duration - player_state?.seek)
                      )
                    )}
                  </div>

                  {/* STATUS */}
                  <div>
                    Status:{" "}
                    {player_state?.isMediaLoaded
                      ? "Loaded"
                      : player_state?.isBuffering
                      ? "Buffering"
                      : "Loaded"}
                  </div>
                </div>

                <div className="flex items-center content-center">
                  Playback:
                  <select
                    className="bg-red-600 text-white border border-white rounded-md ml-1 text-xs py-0.5 w-32"
                    defaultValue={player_state?.playbackRate}
                    onChange={(e) => doPlayback(e.target.value)}
                  >
                    {playbackRate_array.map((e, index) => {
                      return (
                        <option key={index} value={e.speed}>
                          {e.text}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* SEEK */}
                <div className="flex items-center content-center">
                  <div className="flex-1 text-sm mt-5 px-2">
                    <Slider
                      axis="x"
                      x={player_state?.seek_slider}
                      xmax={100}
                      //disabled={player_state?.isPlaying}
                      onChange={({ x }) => {
                        doSeekbyRatio(x);
                      }}
                      styles={player_style(player_state?.isPlaying)}
                    />
                  </div>
                </div>

                {/* PLAY BUTTONS */}

                {/* FILE */}

                <div className="flex items-center content-center text-center my-5">
                  <div className="flex-1">
                    <></>
                  </div>
                  <button
                    className="flex-0 cursor-pointer font-bold rounded-full border hover:border-white h-12 w-12 my-auto mx-auto text-center justify-center text-md flex items-center content-center"
                    tabIndex={0}
                    onClick={() =>
                      doSeekbySeconds(
                        player_state.seek - 15 < 0 ? 0 : player_state.seek - 15
                      )
                    }
                  >
                    <div>-15</div>
                  </button>

                  {/* PLAY / PAUSE */}
                  {!player_state?.isEnded && (
                    <button
                      className="flex-1 cursor-pointer mx-3 opacity-100 hover:opacity-90"
                      tabIndex={0}
                      onClick={() => togglePlay()}
                      title={player_state?.isPlaying ? "pause" : "play"}
                    >
                      <SvgJsx
                        type={"fill"}
                        icon={player_state?.isPlaying ? "pause-sm" : "play-sm"}
                        className={`h-24 w-24   mx-auto `}
                        title={player_state?.isPlaying ? "pause" : "play"}
                      />
                    </button>
                  )}

                  {/* ENDED */}
                  {player_state?.isEnded && (
                    <button
                      className="flex-1 cursor-pointer mx-5 my-2 opacity-100 hover:opacity-90 "
                      tabIndex={0}
                      onClick={() => {
                        set_player_state({
                          ...player_state,
                          isPlaying: !player_state?.isPlaying,
                          isEnded: false,
                          seek: 0,
                          seek_slider: 0,
                          seek_ratio: 0,
                        });
                      }}
                    >
                      <div className="h-20 w-20 rounded-full mx-auto bg-white flex items-center content-center justify-center">
                        <SvgJsx
                          type={"fill"}
                          icon={"refresh-sm"}
                          className={` h-12 w-12 text-red-500 rounded-full`}
                          title={"restart"}
                        />
                      </div>
                    </button>
                  )}

                  <button
                    className="flex-0 cursor-pointer font-bold rounded-full border hover:border-white h-12 w-12 my-auto mx-auto text-center justify-center text-md flex items-center content-center"
                    tabIndex={0}
                    onClick={() =>
                      doSeekbySeconds(
                        player_state.seek + 30 >= player_state.duration
                          ? player_state.duration
                          : player_state.seek + 30
                      )
                    }
                  >
                    <div>+30</div>
                  </button>
                  <div className="flex-1">
                    <></>
                  </div>
                </div>

                {/* VOLUME */}
                <div className="flex items-center content-center border-t border-red-900 pt-4">
                  <button
                    className={`flex-0 cursor-pointer opacity-100 hover:opacity-75
                            ${player_state?.isMuted ? " animate-pulse" : ""}
                  `}
                    onClick={() => {
                      set_player_state({
                        ...player_state,
                        isMuted: !player_state?.isMuted,
                      });
                    }}
                  >
                    <SvgJsx
                      type={"fill"}
                      icon={
                        player_state?.isMuted ? "volume-off-sm" : "volume-up-sm"
                      }
                      className={`h-4 w-4  `}
                      title={player_state?.isMuted ? "muted" : "unmuted"}
                    />
                  </button>
                  <button className="flex-1 ml-2 text-sm">
                    <Slider
                      axis="x"
                      x={player_state?.volume_slider}
                      xmax={100}
                      disabled={player_state?.isMuted}
                      onChange={({ x }) => {
                        set_player_state({
                          ...player_state,
                          volume: x / 100,
                          volume_slider: x,
                        });
                        localStorage.setItem("volume_slider", x?.toString());
                      }}
                      styles={{
                        active: {
                          backgroundColor: player_state?.isMuted
                            ? "#555555"
                            : "#0000ff",
                        },
                        track: {
                          backgroundColor: "#111827",
                          display: "flex",
                          height: 15,
                          width: "100%",
                          cursor: "pointer",
                        },
                        thumb: {
                          width: 15,
                          height: 15,
                          backgroundColor: player_state?.isMuted
                            ? "#c0c0c0"
                            : "#ffffff",
                          border: "1px solid #a0a0a0",
                        },
                        disabled: {
                          opacity: 0.5,
                        },
                      }}
                    />
                  </button>
                  <button
                    className="flex-0 ml-3 w-8 text-left text-xs cursor-pointer  opacity-100 hover:opacity-75"
                    onClick={() => {
                      set_player_state({
                        ...player_state,
                        volume: 1,
                        volume_slider: 100,
                        isMuted: false,
                      });
                    }}
                  >
                    {player_state?.isMuted
                      ? "0"
                      : Math.round(player_state?.volume * 100)}
                    %
                  </button>
                </div>
              </div>
            }
            days={days}
            seconds={seconds}
            minutes={minutes}
            hours={hours}
            togglePlay={togglePlay}
          />
        </>
      )}
    </>
  );
}
