import { config } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
import Image from "next/legacy/image";
import { useContext, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import SvgJsx from "../templateux/svg/svg-jsx";
import { PlayerContext } from "../context/player-context";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import PlayerModal from "./player-modal";
import { ifNull } from "@/lib/utils/ifNull";
import { padZero } from "@/lib/utils/padZero";
import { srcSafe } from "@/lib/utils/srcSafe";

export default function PlayerNavUx({
  type,
  open,
  set_open,
  visibleText,
  panelContents,
  days,
  hours,
  minutes,
  seconds,
  togglePlay,
}) {
  const { isConfirmed } = useConfirm();

  const { player_state, set_player_state } = useContext(PlayerContext);
  const [isModalOpen, set_isModalOpen] = useState(false);
  const [clock, set_clock] = useState(`00:00:00`);
  const popupref = useRef(null);
  const playerRef = useRef(null);
  const [isMounted, set_isMounted] = useState(false);

  useEffect(() => {
    set_isMounted(true);
    return () => {};
  }, []);

  const closeMenu = () => {
    if (popupref.current) popupref.current.close();
  };

  const closeModal = () => {
    set_isModalOpen(false);
  };

  if (!isMounted) return <></>;

  return (
    <>
      <div className="flex-1 flex items-center content-center">
        <div
          className="flex-0 ml-2 flex items-center content-center"
          role="button"
        >
          <div className={`flex-0 `}>
            <div className=" flex-0 menu flex items-center content-center relative ">
              <Popup
                trigger={(isOpen) => (
                  <div
                    className={`flex-0  shrink-0 flex items-center content-center font-bold p-0 pl-0.5 cursor-pointer
                                    
                                    
                                    ${
                                      player_state?.broadcast_status?.toString() ==
                                        "1" || type == "file"
                                        ? "bg-red-600 hover:bg-red-500 border-red-700 text-white "
                                        : ` bg-gray-500 text-gray-200 `
                                    }

                                    ${
                                      type == "file" ||
                                      (player_state?.broadcast_status?.toString() ==
                                        "1" &&
                                        !player_state?.isEnded)
                                        ? "rounded-l-full"
                                        : "rounded-full"
                                    }
                                    
                                    `}
                    onClick={() => {
                      open !== "player" ? set_open("player") : set_open("");
                    }}
                  >
                    <div className="flex-0 flex items-center content-center  shrink-0">
                      <Image
                        src={
                          hasData(srcSafe(player_state?.avatar_url))
                            ? srcSafe(player_state?.avatar_url)
                            : config.avatar.url_missing
                        }
                        alt={player_state?.user_name}
                        className={`h-7 w-7 rounded-full shrink-0 bg-white  object-cover
                                          ${
                                            //LIVE
                                            (player_state?.player_type ==
                                              "live" &&
                                              player_state?.broadcast_status?.toString() ==
                                                "1") ||
                                            //FILE
                                            (player_state?.player_type ==
                                              "file" &&
                                              player_state.isPlaying)
                                              ? "animate-spin-slow"
                                              : ""
                                          }
                                          `}
                        width={24}
                        height={24}
                        quality={100}
                      />
                    </div>
                    <div className="flex-0 ml-1 flex items-center content-center">
                      <div className="flex-0 uppercase ml-0.5 mr-2 min-w-[48px] flex items-center content-center py-1 text-sm">
                        {visibleText}
                      </div>
                      <div
                        className="flex-0  flex items-center content-center"
                        role="button"
                      >
                        <SvgJsx
                          type={"fill"}
                          icon={"chevron-down-sm"}
                          className={`h-5 w-5  `}
                          title={"open menu"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                position="bottom left"
                on="click"
                open={open}
                onOpen={() => set_open("player")}
                closeOnDocumentClick
                closeOnEscape
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "transparent",
                  zIndex: 1999,
                }}
                arrow={false}
                nested
                ref={popupref}
              >
                <div
                  className={`menu fixed top-[45px] left-0 sm:left-auto sm:w-64 w-full flex flex-col gap-y-1 rounded-md shadow-xl p-2 mt-0.5
                          
                    ${
                      player_state?.broadcast_status?.toString() == "1" ||
                      type == "file"
                        ? "bg-red-600 hover:bg-red-500 border-red-700 text-white"
                        : ` bg-gray-500 text-gray-200`
                    }
                          
                          `}
                >
                  <div>{panelContents}</div>
                  <div
                    className={`mt-1 border-t pt-2 ${
                      player_state?.broadcast_status?.toString() == "1"
                        ? `border-red-900`
                        : `border-gray-600`
                    }`}
                  >
                    <button
                      className="border rounded-md border-white px-2 py-1"
                      onClick={() => {
                        set_isModalOpen(true);
                        set_clock(
                          `${padZero(hours)}:${padZero(minutes)}:${padZero(
                            seconds
                          )}`
                        );
                      }}
                    >
                      Save timestamp
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
          </div>

          {/* PLAY/PAUSE */}
          {!player_state?.isEnded && (
            <div
              className={`flex-0 rounded-r-full flex items-center content-center font-bold p-0 h-7
                              cursor-pointer bg-red-600 hover:bg-red-500   text-white
                              
                            `}
              onClick={() => {
                togglePlay();
              }}
            >
              <div className="flex-0 flex items-center content-center">
                <div className="flex-0 p-0 pr-0.5" role="button">
                  <SvgJsx
                    type={"fill"}
                    icon={
                      player_state?.isPlaying
                        ? "pause-large-sm"
                        : "play-large-sm"
                    }
                    className={`h-6 w-6 p-1 mt-0.5
                            
                            ${player_state?.isPlaying ? "" : "animate-pulse"}
                            `}
                    title={player_state?.isPlaying ? "pause" : "play"}
                  />
                </div>
              </div>
            </div>
          )}

          {/* ENDED */}
          {type == "file" && player_state?.isEnded && (
            <div
              className={`flex-0 border-l rounded-r-full flex items-center content-center font-bold p-1 
                          cursor-pointer 
                            ${
                              player_state.isPlaying ||
                              player_state?.broadcast_status?.toString() == "0"
                                ? ""
                                : "animate-pulse"
                            }
                              bg-red-600 hover:bg-red-500 border-red-700 text-white
                              
                            `}
              onClick={() => {
                set_player_state({
                  ...player_state,
                  isPlaying: !player_state?.isPlaying,
                  isEnded: false,
                  seek: 0,
                  seek_slider: 0,
                  seek_ratio: 0,
                });
              }}
            >
              <div className="h-5 w-5 rounded-full mx-auto  flex items-center content-center justify-center">
                <SvgJsx
                  type={"fill"}
                  icon={"refresh-sm"}
                  className={` h-12 w-12 text-white rounded-full`}
                  title={"restart"}
                />
              </div>
            </div>
          )}
        </div>

        {/* CLOSE */}
        <div
          className={`flex-0  flex items-center content-center font-bold p-0 cursor-pointer ml-1
                          
                         text-gray-600 hover:text-gray-400`}
          onClick={async () => {
            const confirmed = await isConfirmed(
              "Are you sure you want to close and hide the player?"
            );

            if (confirmed) {
              set_player_state({
                ...player_state,
                file: "",
                title: "",
                seek: 0,
                seek_ratio: 0,
                seek_slider: 0,
                length: 0,
                user_id: 0,
                user_name: "",
                content_id: 0,
                content_id_last: 0,
                post_id: 0,
                post_id_last: 0,
                content_table: "",
                avatar_url: "",
                isHide: "1",
                playerRef: playerRef,
              });
              closeMenu();
            }
          }}
        >
          <div className="flex-0 flex items-center content-center">
            <div className="flex-0">
              <SvgJsx
                type={"fill"}
                icon={"x-sm"}
                className={`h-4 w-4  `}
                title={"close"}
              />
            </div>
          </div>
        </div>

        <PlayerModal
          modal_panel={{ id: "timestamp" }}
          isModalOpen={isModalOpen}
          clock={clock}
          set_clock={set_clock}
          closeModal={closeModal}
          sendmsg={() => {}}
          channel_id={""}
          host_id={player_state?.user_id}
          post_id={ifNull(player_state?.post_id, 0)}
          content_id={player_state?.content_id}
        />
      </div>
    </>
  );
}
