import SvgJsx from "@/components/templateux/svg/svg-jsx";
import ReactTooltip from "react-tooltip";
import showdown from "showdown";
import parsehtml from "@/lib/utils/parsehtml";
import { hasData } from "@/lib/utils/hasData";
import { support_messages } from "@/lib/config";
import { useState, useEffect } from "react";

export default function HelpTip({
  id,
  tip,
  className = "float-right block relative m-auto ",
  placement = "right",
}) {
  var converter = new showdown.Converter();
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!hasData(tip)) {
    return <></>;
  }

  return (
    <>
      <a
        data-tip
        data-for={`helptip-${id}`}
        className={`${className} cursor-pointer text-gray-300 hover:text-gray-400  w-5 h-5  text-center align-middle text-xs`}
      >
        <SvgJsx
          type="fill"
          icon="help-sm"
          className="w-5 h-5"
          title="Help Tips"
          color="currentColor"
        />
      </a>
      {isMounted && (
        <ReactTooltip
          id={`helptip-${id}`}
          //@ts-ignore
          place={`${placement}`}
          effect="solid"
          type="info"
          clickable={true}
          delayHide={500}
          delayUpdate={500}
          className="reacttooltip rounded-3xl shadow-l"
        >
          <div className="w-64 helptips markdown">
            {parsehtml(
              converter.makeHtml(tip || support_messages.missing_info)
            )}
          </div>
        </ReactTooltip>
      )}
    </>
  );
}
