import ModalForm from "@/components/templateux/modal/modal-form";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import {
  USER_PERMS,
  config,
  getBroadcasterUrl,
  lookupPost,
} from "@/lib/config";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { openWindow } from "@/lib/utils/openWindow";
import { slugify } from "@/lib/utils/slugify";
import router from "next/router";
import { Fragment, useContext, useEffect, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import Popup from "reactjs-popup";
import PostForm from "../../profile/post/post-form";
import RotaryLogo from "../rotary-logo";
import SoundboardForm from "@/components/profile/soundboard/soundboard-form";
import { hasData } from "@/lib/utils/hasData";
import PrerecordingForm from "@/components/profile/prerecording/prerecording-form";
import { getBitwise } from "@/lib/utils/bitoperations";
import { TribeContext } from "@/components/context/tribe-context";
import { textProcess } from "@/lib/utils/textprocess";

export default function MenuCreateDropdown({
  classColor,
  session,
  userdata,
  open,
  set_open,
}) {
  const { isConfirmed } = useConfirm();

  const [isMounted, set_isMounted] = useState(false);

  const ssr_data_tribe = useContext(TribeContext);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  //HOOKS ABOVE SECURITY CALLS
  const cache_date = +new Date();
  const [cache, set_cache] = useState(
    encodeURIComponent(cache_date?.toString())
  );

  //SUBMISSION STATES
  const [submitting, set_submitting] = useState(false);
  const [processing, set_processing] = useState(-1);
  const [changes, set_changes] = useState(false);
  const [form_reset, set_form_reset] = useState(1);
  const [typevalue, set_typevalue] = useState(0);

  //MODAL FUNCTIONS
  const [isOpen, set_isOpen] = useState(null); //modal

  useEffect(() => {
    set_isOpen(null);
  }, []);
  async function closeModal() {
    if (changes) {
      const confirmed = confirm(
        "You have unsaved changes. Are you sure you want to close?"
      );
      //const confirmed = await isConfirmed('You have unsaved changes. Are you sure you want to close?');
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
      if (confirmed) {
        set_isOpen(null);
        set_form_reset(1);
        set_changes(false);
      }
    } else {
      set_isOpen(null);
      set_form_reset(1);
    }
  }

  function openModal(n, value) {
    if (n == 0) set_typevalue(value);
    set_isOpen(n);
  }

  async function submitHandler(
    submitbody,
    api_destination,
    api_method = "POST",
    api_headers = {
      "Content-Type": "application/json",
    },
    toast_message = "Your changes were saved."
  ) {
    set_submitting(true);

    //console.log(JSON.stringify(submitbody))

    try {
      //INFO SUBMIT

      set_submitting(true);
      const res = await fetch(api_destination, {
        method: api_method,
        headers: api_headers,
        body: JSON.stringify(submitbody),
      });

      //console.log("res",res)

      set_changes(false);
      set_submitting(false);
      const json = await res.json();
      if (json) {
        const {
          outcome,
          post_id,
          post_title,
          soundboard_id,
          soundboard_title,
          slug,
          user_name,
        } = json[0][0];
        // console.log(json,submitbody);
        if (outcome?.toString() == "1") {
          toastConfig({
            time: 5000,
            className: "",
            position: "right",
          });
          toast(toast_message, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else {
          alert(outcome);
        }
        let submit_cache = new Date();
        set_cache(encodeURIComponent(submit_cache?.toString()));
        set_isOpen(null);
        if (hasData(post_id))
          router.push(`/${user_name}/posts/${slugify(post_id, slug)}`);
        if (hasData(soundboard_id)) {
          router.push(
            `/${user_name}/soundboards/${slugify(
              soundboard_id,
              slug
            )}?show=upload`
          );
        }
      }
    } catch (e) {
      throw Error(e.message);
    }
  }

  // const ref = useRef(null);
  // const closeMenu = () => {if (ref.current) ref.current.close()};

  const PopupTrigger = (
    <button
      onClick={() => {
        open !== "create" ? set_open("create") : set_open("");
      }}
      className={`${classColor} flex-0 flex items-center content-center rounded-md shadow-md hover:animate-pulse mr-2`}
    >
      <div className="flex-1 rounded-l-md">
        <div
          className={` 
                          
                            cursor-pointer text-center 
                            pl-2 pr-2 xs:pr-1 py-1 flex font-bold text-md
                            
                            
                            `}
        >
          <SvgJsx
            type={"fill"}
            icon={"plus-sm"}
            className={`h-6  w-6 flex `}
            title={"Create or Go Live"}
          />
        </div>
      </div>
      <div
        className="flex-0 cursor-pointer hidden xs:block
                           pr-1 py-1 xs:pr-3 rounded-r-md 
                          "
      >
        {textProcess(`Create`)}
      </div>
    </button>
  );

  return (
    <>
      <div className="menu flex items-center content-center  flex-0 z-50">
        {isMounted && session && (
          <>
            <div className="group">
              <Popup
                position="bottom left"
                open={open}
                onOpen={() => set_open("create")}
                //onClose={()=>set_open('')}
                trigger={PopupTrigger}
                //on="click"
                closeOnDocumentClick
                closeOnEscape
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "transparent",
                  zIndex: 1999,
                }}
                arrow={false}
                nested
                //ref={isMounted ? ref : null}
              >
                <div className="menu ring-0 fixed top-[47px] right-[0px] w-full sm:w-64 ">
                  <div
                    className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2 
                                              bg-red-600 "
                  >
                    {/* BROADCAST */}
                    <MenuOption
                      onClick={() => {
                        if (
                          getBitwise(
                            userdata?.credentials_bitarray,
                            Object.keys(USER_PERMS)
                          )["broadcastlive"]
                        ) {
                          if (typeof window !== undefined) {
                            openWindow(
                              getBroadcasterUrl(userdata?.user_name),
                              config.broadcast.title,
                              window,
                              config.broadcast.width,
                              config.broadcast.height
                            );
                            set_open("");
                          }
                        } else {
                          alert(
                            `Your account does not have access to this feature yet. Ask an admin or email ${ssr_data_tribe?.email_support} for beta access.`
                          );
                        }
                      }}
                      icon_override={
                        <>
                          <RotaryLogo
                            classSize="w-5 h-5 mx-auto block"
                            classAnimate=" animate-spin-slow"
                            bgColor="transparent"
                            centerColor="rgba(31,41,55,1)"
                            pointerColor="#ff0000"
                            holeColor="#111827"
                            dialerColor="#ffffff"
                            size="16"
                          />
                        </>
                      }
                      icon={""}
                      title={textProcess(`Broadcast a Live Show`)}
                      userdata={userdata}
                      type={"broadcastlive"}
                    />

                    {/* POST */}
                    <MenuOption
                      onClick={() => {
                        if (
                          getBitwise(
                            userdata?.credentials_bitarray,
                            Object.keys(USER_PERMS)
                          )["post"]
                        ) {
                          openModal(0, "-1"); //post chooser
                          set_open("");
                        } else {
                          alert(
                            `Your account does not have access to this feature yet. Ask an admin or email ${ssr_data_tribe?.email_support} for beta access.`
                          );
                        }
                      }}
                      icon={"newspaper-sm"}
                      title={textProcess("Create a Post")}
                      userdata={userdata}
                      type={"post"}
                    />

                    {/* SOUNDBOARD */}
                    <MenuOption
                      onClick={() => {
                        if (
                          getBitwise(
                            userdata?.credentials_bitarray,
                            Object.keys(USER_PERMS)
                          )["soundboard"]
                        ) {
                          openModal(0, 9); //soundboard
                          set_open("");
                        } else {
                          alert(
                            `Your account does not have access to this feature yet. Ask an admin or email ${ssr_data_tribe?.email_support} for beta access.`
                          );
                        }
                      }}
                      icon={"grid-sm"}
                      title={textProcess(`Create a Soundboard`)}
                      userdata={userdata}
                      type={"soundboard"}
                    />

                    {/* {[
                                   lookupPost("audio",-1)
                                  ,lookupPost("videourl",-1)
                                  ,lookupPost("image",-1)
                                  ,lookupPost("text",-1)
                                  ,lookupPost("soundboard",-1)
                                 // ,lookupPost("prerecording",-1)
                                ]?.map((e,index) => 
                                  <Fragment key={index}>
                                    
                                    <PopupMenuOption
                                    //todo - update this when soundboards are live
                                      isDisplay={e?.type !== "soundboard" || userdata?.bol_soundboards?.toString()=="1"}
                                      onClick={()=>{
                                        // openModal(0,e?.value);
                                        openModal(0,"-1"); //post chooser
                                        set_open('');
                                      }}
                                      icon={e?.icon}
                                      title={e?.action}
                                      colorClass={`
                                      bg-red-900 hover:bg-red-700 hover:text-white text-red-100 `}
                                    />
                                  </Fragment>
                                  )} */}

                    {/* {[
                                  lookupPost("draw",-1)
                                ]?.map((e,index) => 
                                  <Fragment key={index}>
                                    <PopupMenuOption
                                      isDisplay={true}
                                      onClick={()=>{
                                        if (typeof window !== undefined) {
                                          openWindow(config.draw.app_base_url, config.draw.title, window, window.innerWidth-1, window.innerHeight-1);
                                          set_open('');
                                        }
                                      }}
                                      icon={e?.icon}
                                      title={e?.action}
                                      colorClass={`
                                      bg-red-900 hover:bg-red-700 hover:text-white text-red-100 `}
                                    />
                                  </Fragment>
                                  )} */}
                  </div>
                </div>
              </Popup>
            </div>

            <ModalForm
              title={
                {
                  "-1": <>Create a New Post</>,
                }[typevalue] || `New ${lookupPost("", typevalue)?.text}`
              }
              modal_content={
                {
                  "-1": (
                    <div className="sm:grid grid-cols-2 gap-2  w-full">
                      {[
                        lookupPost("text", -1),
                        lookupPost("image", -1),
                        lookupPost("audio", -1),
                        lookupPost("videourl", -1),
                        // ,lookpPost("prerecording",-1)
                      ]?.map((e, index) => (
                        <div key={index} className="flex-1 w-full mb-2 sm:mb-0">
                          {/* <PopupMenuOption
                                            //todo - update this when soundboards are live
                                              isDisplay={e?.type !== "soundboard" || userdata?.bol_soundboards?.toString()=="1"}
                                              onClick={()=>{
                                                openModal(0,e?.value);
                                                set_open('');
                                              }}
                                              icon={e?.icon}
                                              title={e?.action}
                                              colorClass={`
                                              bg-gray-800 hover:bg-gray-700 hover:text-white text-gray-100 `}
                                            /> */}

                          <MenuOption
                            onClick={() => {
                              openModal(0, e?.value);
                              set_open("");
                            }}
                            icon_override={
                              <>
                                <SvgJsx
                                  type={"fill"}
                                  icon={e?.icon}
                                  className={` h-10 w-10 group-hover:opacity-80 group-hover:animate-wiggle`}
                                  title={e?.action}
                                />
                              </>
                            }
                            icon={e?.icon}
                            title={e?.action}
                            userdata={userdata}
                            type={"post"}
                            colorClass={`   bg-gray-800 hover:bg-gray-700 hover:text-white text-gray-100 `}
                          />
                        </div>
                      ))}
                    </div>
                  ),
                  //soundboard
                  9: (
                    <>
                      <SoundboardForm
                        submitHandler={submitHandler}
                        submitting={submitting}
                        set_submitting={set_submitting}
                        processing={processing}
                        set_processing={set_processing}
                        set_changes={set_changes}
                        changes={changes}
                        max_files={config.post.max_files}
                        //post info
                        soundboard_id={isOpen}
                        itemdata={{}}
                        parentdata={{}}
                        itemdataisLoading={false}
                        itemdataisError={false}
                        ownerdata={userdata}
                        userdata={userdata}
                        show_graphic={false}
                        form_reset={form_reset}
                        set_form_reset={set_form_reset}
                        actionText={"Save changes & add sounds"}
                        soundboardcache={cache}
                        set_soundboardcache={set_cache}
                        navtype={"soundboard"}
                        helptype={"broadcast"}
                        typevalue={typevalue}
                        set_typevalue={set_typevalue}
                      />
                    </>
                  ),
                  //Prerecording
                  17: (
                    <>
                      <PrerecordingForm
                        submitHandler={submitHandler}
                        submitting={submitting}
                        set_submitting={set_submitting}
                        processing={processing}
                        set_processing={set_processing}
                        set_changes={set_changes}
                        changes={changes}
                        max_files={config.post.max_files}
                        //post info
                        prerecording_id={isOpen}
                        itemdata={{}}
                        parentdata={{}}
                        itemdataisLoading={false}
                        itemdataisError={false}
                        ownerdata={userdata}
                        userdata={userdata}
                        show_graphic={false}
                        form_reset={form_reset}
                        set_form_reset={set_form_reset}
                        actionText={"Save changes"}
                        prerecordingcache={cache}
                        set_prerecordingcache={set_cache}
                        navtype={"prerecording"}
                        helptype={"broadcast"}
                        typevalue={typevalue}
                        set_typevalue={set_typevalue}
                      />
                    </>
                  ),
                }[typevalue] || (
                  //otherwise, it's a post
                  <>
                    <PostForm
                      submitHandler={submitHandler}
                      submitting={submitting}
                      set_submitting={set_submitting}
                      processing={processing}
                      set_processing={set_processing}
                      set_changes={set_changes}
                      changes={changes}
                      max_files={config.post.max_files}
                      //post info
                      post_id={isOpen}
                      itemdata={{}}
                      parentdata={{}}
                      itemdataisLoading={false}
                      itemdataisError={false}
                      ownerdata={userdata}
                      userdata={userdata}
                      show_graphic={false}
                      form_reset={form_reset}
                      set_form_reset={set_form_reset}
                      actionText={"Save changes"}
                      postcache={cache}
                      set_postcache={set_cache}
                      navtype={"post"}
                      helptype={"broadcast"}
                      typevalue={typevalue}
                      set_typevalue={set_typevalue}
                    />
                  </>
                )
              }
              isOpen={isOpen}
              closeModal={closeModal}
            />
          </>
        )}
      </div>
    </>
  );
}

function LockTreatment({ userdata, credential_type }) {
  if (
    !getBitwise(userdata?.credentials_bitarray, Object.keys(USER_PERMS))[
      credential_type
    ]
  ) {
    return (
      <>
        <div>
          <SvgJsx
            type={"fill"}
            icon={"lock-closed-sm"}
            className={`h-4  w-4 flex `}
            title={"Locked"}
          />
        </div>
      </>
    );
  }

  return <></>;
}

function MenuOption({
  onClick,
  icon,
  icon_override = null,
  title,
  userdata,
  type,
  colorClass = `  bg-red-900 hover:bg-red-700 hover:text-white text-red-100`,
}) {
  return (
    <>
      <div className="menu-item ">
        <button
          tabIndex={0}
          className={`font-bold group cursor-pointer rounded-md flex items-center content-center justify-left w-full px-2 py-2 text-sm leading-5 text-left ring-0
                                      
                                    ${colorClass}
                                      
                                      
                                      `}
          onClick={onClick}
        >
          <div className={`flex-0 justify-left text-sm  `}>
            <div className="flex-0  mr-1">
              {hasData(icon_override) && icon_override}
              {!hasData(icon_override) && (
                <SvgJsx
                  type={"fill"}
                  icon={icon}
                  className={` h-4 w-4`}
                  title={title}
                />
              )}
            </div>
          </div>
          <div className="ml-2 flex-1 ">{title}</div>

          <LockTreatment userdata={userdata} credential_type={type} />
        </button>
      </div>
    </>
  );
}
