import { hasData } from "./hasData";

export function slugify(id, words) {
  var vid = id;
  return `${vid}-${slugtext(words)}`;
}

export function sluginput(id, text, placeholder) {
  return slugify(id, hasData(text) ? text : placeholder).replace(`${id}-`, "");
}

export function slugtext(words) {
  var vwords = hasData(words)
    ? words?.replace(/[^a-zA-Z0-9-]/g, "-")
    : `untitled`;
  vwords = vwords?.replace(/--+/g, "-");
  return vwords?.toLowerCase();
}
