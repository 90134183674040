import { handleGenerate } from "@/components/generate/handleGenerate";
import { hasData, hasNoData } from "@/lib/utils/hasData";
import GatingIcon from "../gating/icon";
import { useEffect, useState } from "react";
import { usePrompt } from "@/lib/utils/hooks/usePrompt";
import ModalPrompt from "../modal/modal-prompt";

export default function DropzoneHeader({
  url,
  set_json,
  empty_value,
  type,
  title,
  processing,
  set_processing,
  generate_prompt,
}) {
  const [this_prompt, set_this_prompt] = useState(generate_prompt);

  useEffect(() => {
    set_this_prompt(generate_prompt);
  }, [generate_prompt]);

  const { isPrompted } = usePrompt();

  async function onGenerate(n) {
    await set_json(hasNoData(JSON.stringify(n), empty_value));
    await setTimeout(() => {
      set_processing(false);
    }, 5000);
  }

  return (
    <>
      <div className="flex items-center content-center">
        <div className="flex-1">
          <div className="flex items-center content-center">
            <div className="flex-0">
              <h3 className="font-bold">{title}</h3>
            </div>
          </div>
        </div>

        {hasData(url) && (
          <div className="text-right text-xs mr-2 ">
            <button
              onClick={async () => {
                const prompted = await isPrompted(
                  "Are you sure you want to replace the original file?"
                );
                if (prompted) set_json();
              }}
              className="border px-2 py-1 font-bold rounded-full border-gray-700 hover:border-white"
            >
              remove file
            </button>
          </div>
        )}

        {type == "image" && (
          <div className="text-right text-xs ">
            <button
              onClick={async (e) => {
                e.preventDefault();
                if (
                  hasData(url) &&
                  !confirm("Are you sure you want to remove this file?")
                ) {
                  return false;
                }

                //const prompted = await isPrompted({
                await isPrompted({
                  title: "Enter a clear and descriptive prompt",
                  // body: (
                  //   <div className="text-wrap italic break-normal">
                  //     Notes: No sexual or violent descriptions.
                  //   </div>
                  // ),
                  show_input: true,
                  confirm: "Generate with AI",
                  show_title: true,
                  show_body: false,
                  onSubmit: async (prompt: string) => {
                    set_this_prompt(prompt);
                    await set_json(empty_value);
                    await set_processing(true);
                    await handleGenerate(
                      // prompt.inputvalue,
                      prompt,
                      () => set_processing(true),
                      (n) => onGenerate([n])
                    );
                  },
                });
              }}
              className="border px-2 py-1 font-bold rounded-full border-gray-700 hover:border-white"
            >
              <div className="flex items-center content-center">
                <div className="flex-0 mr-2">
                  <GatingIcon
                    id={"AI-Generate"}
                    tip={"Premium Only"}
                    classSize={`w-4 h-4`}
                  />
                </div>
                <div>Generate</div>
              </div>
            </button>
          </div>
        )}
      </div>
      <ModalPrompt />
    </>
  );
}
