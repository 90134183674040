import { signIn } from "next-auth/client";
import Button from "../button/button";
import SvgJsx from "../svg/svg-jsx";
import { textProcess } from "@/lib/utils/textprocess";

function MenuButton() {
  return (
    <>
      <div>
        <div
          className={`flex flex-none items-center content-center cursor-pointer whitespace-nowrap border group  p-0.5 rounded-md text-sm font-medium 
          
          text-white   hover:text-white
        border-transparent
          `}
          id="main-menu"
          aria-haspopup="true"
        >
          <Button className="" onClick={signIn}>
            <div
              className={`bg-red-600 hover:bg-red-500  flex-0 flex items-center content-center rounded-md shadow-md border border-transparent hover:border-white text-white `}
            >
              <div className="flex-1 rounded-l-md">
                <div
                  className={` 

                            cursor-pointer text-center 
                            xs:pl-2 xs:pr-0 py-1 flex font-bold text-md
                            
                            
                            `}
                >
                  <SvgJsx
                    type={"fill"}
                    icon={"group-sm"}
                    className={`h-4  w-4 mr-1 hidden xs:block`}
                    title={"Create or Go Live"}
                  />
                </div>
              </div>
              <div
                className="flex-0 cursor-pointer block
                            py-1 px-2 xs:pr-3 rounded-r-md  
                          "
              >
                {textProcess(`Join Us`)}
              </div>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

export default MenuButton;
