import { countUpBySeconds } from "./countUp";
import { padZero } from "./padZero";

export const timerFormat = (timerobj, show_day = false) => {
  return `${show_day ? `${padZero(timerobj.days)}:` : ``}${padZero(
    timerobj.hours
  )}:${padZero(timerobj.minutes)}:${padZero(timerobj.seconds)}`;
};

export const timerFormatSeconds = (seconds, show_day = false) => {
  let timerobj = countUpBySeconds(seconds);
  return `${show_day ? `${padZero(timerobj.days)}:` : ``}${padZero(
    timerobj.hours
  )}:${padZero(timerobj.minutes)}:${padZero(timerobj.seconds)}`;
};

export const timerFormatClip = (timerobj) => {
  return `${padZero(timerobj.minutes)}:${padZero(timerobj.seconds)}`;
};

export const timerFormatDHMS = (timerobj, shorten = false) => {
  const show_d = timerobj.days > 0;
  const show_h = show_d || timerobj.hours > 0;
  const show_m = show_h || timerobj.minutes > 0;
  const show_s = true;

  if (shorten)
    return `${show_d ? `${timerobj.days}d ` : ``}${
      show_h ? `${timerobj.hours}h ` : ``
    }${show_m ? `${timerobj.minutes}m ` : ``}${
      show_s ? `${timerobj.seconds}s ` : ``
    }`;
  //otherwise dont shorten
  return `${show_d ? `${timerobj.days} days, ` : ``}${
    show_h ? `${timerobj.hours} hours, ` : ``
  }${show_m ? `${timerobj.minutes} minutes, ` : ``}${
    show_s ? `${timerobj.seconds} seconds` : ``
  }`;
};
