import { config, lookupPost, tablehelptype, time } from "@/lib/config";
import { bumber } from "@/lib/utils/bumber";
import {
  domInputStringifyDateObject,
  extractDateObject,
} from "@/lib/utils/extractTimeObject";
import { formatBytes } from "@/lib/utils/formatBytes";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
// import { useUserAuth } from '@/lib/utils/hooks/useUserAuth';
import { padZeroVariable } from "@/lib/utils/padZero";
import { useSession } from "next-auth/client";
// import { now } from 'moment';
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import moment from "moment-timezone";
import toast from "react-simple-toasts";
import TextareaAutosize from "react-textarea-autosize";
import ReactTooltip from "react-tooltip";
import HelpTip from "../help-form/help-tip";
import SelectListItem from "../select-list-item";
import SvgJsx from "../svg/svg-jsx";
import ToggleButton from "../toggle/toggle-button";
import isAdminUser from "@/lib/utils/isAdminUser";
import { useUserAuth } from "@/lib/utils/hooks/useUserAuth";
import { srcSafe } from "@/lib/utils/srcSafe";
// export { default as draftToMarkdown } from './draft-to-markdown';
// export { default as markdownToDraft } from './markdown-to-draft';
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css;
// import Editor from "rich-markdown-editor";

export default function FormInput({
  name,
  title,
  type = "text",
  category = "",
  options = [],
  meta = {
    maxChars: 0,
    minRows: 4,
    triggerArray: [""],
    triggerText: [<></>],
    max_files: config.post.max_files,
    max_duration: config.post.max_duration,
    disable_captions: false,
    disable_alt: false,
    autoFocus: false,
  },
  placeholder = "",
  description = "",
  helptip = "",
  value,
  set_value,
  submitting,
  set_submitting,
  changes,
  set_changes,
  hidden_value = "",
  set_hidden_value = (n) => {},
  processing = -1,
  set_processing = (n) => {},
  index = 0,
  show_condition = true,
  isEditable = true,
  identifier = `container`,
}) {
  const { isConfirmed } = useConfirm();

  //TIMEZONE DATA

  // const { userdata, isUserDataLoading, isUserDataError } = useUserAuth()

  const [session, loading] = useSession();

  const { userdata, isUserDataLoading, isUserDataError } = useUserAuth();

  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),);

  // const [isPageLoaded, set_isPageLoaded] = useState(false)

  // useEffect(()=>{set_isPageLoaded(true)},[])

  const [original_hidden_value, set_original_hidden_value] =
    useState(hidden_value);
  const [uploading, set_uploading] = useState(false);
  const [upload_complete, set_upload_complete] = useState(false);
  const [edit_date, set_edit_date] = useState(
    domInputStringifyDateObject(value)
  );

  const [file_loaded, set_file_loaded] = useState(true);
  const [file_loaded_attempts, set_file_loaded_attempts] = useState(0);

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  }, []);

  //image sizing function
  function checkImageSize(file) {
    return new Promise<void>(function (resolve, reject) {
      const maxWidth = 2300;
      const maxHeight = 2300;
      const blob = file.originalFile;

      // Get an object URL for the local file
      const url = URL.createObjectURL(blob);

      // Create an image and load the object URL
      const img = new Image();
      img.src = url;

      img.onload = function () {
        URL.revokeObjectURL(url);

        let thisfile = this as HTMLImageElement;

        if (thisfile.naturalWidth > maxWidth) {
          console.log("thisfile.naturalWidth", thisfile.naturalWidth);
          reject("File is too wide");
        }

        if (thisfile.naturalHeight > maxHeight) {
          console.log("thisfile.naturalHeight", thisfile.naturalHeight);
          reject("File is too tall");
        }

        // If we made it here then the file was approved
        set_processing(true);
        resolve();
      };
    });
  }

  const label = (
    <label htmlFor={name}>
      <h3 className="font-bold">{title}</h3>
      <div className="italic text-sm">{description}</div>
    </label>
  );

  const preview_item = (type, value, set_value, index) => {
    return (
      <>
        <div className="flex items-center content-center mt-2 mb-1">
          <div className="flex-1">
            <label htmlFor={name}>
              <h4 className="italic">{`${lookupPost(type, -1)?.short} ${
                index + 1
              }`}</h4>
            </label>
          </div>
          {isEditable && (
            <div
              className="flex-0 flex contents-center items-center ml-4 underline hover:no-underline cursor-pointer"
              onClick={async () => {
                const confirmed = confirm(
                  `Are you sure you want to remove attachment ${
                    lookupPost(type, -1)?.short
                  } ${index + 1}?`
                );
                // const confirmed = await isConfirmed(`Are you sure you want to remove attachment ${lookupPost(type,-1)?.short} ${index+1}?`);
                // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
                if (confirmed) {
                  let tempobj = value;
                  tempobj.splice(index, 1);
                  set_value(JSON.stringify(tempobj));
                  //TODO: Delete the attachment itself from AWS
                }
              }}
            >
              remove
            </div>
          )}
        </div>
        <div className="flex  border border-gray-700 rounded-md p-2 mb-4 bg-gray-700 ">
          <div className="flex-1 ">
            {["image", "avatarupload"].indexOf(type) > -1 && (
              <div className="">
                <div className="w-full text-center justify-center flex items-center content-center">
                  <img
                    src={value[index]?.url}
                    className="text-center w-full border"
                    alt={hasData(value[index]?.alt) ? value[index]?.alt : ""}
                  />
                </div>

                <div className="mt-4">
                  <label htmlFor={`${type}-caption-${index}`}>Caption</label>
                  <TextareaAutosize
                    name={`${type}-caption-${index}`}
                    id={`${type}-caption-${index}`}
                    value={
                      hasData(value[index]?.caption)
                        ? value[index]?.caption
                        : ""
                    }
                    placeholder={"Enter an optional caption"}
                    onChange={(e) => {
                      let new_obj = value;
                      let newval = e.target.value;
                      let old_details = value[index];

                      let new_details = { ...old_details, caption: newval };

                      //replace index with new index contents if index exists
                      new_obj.splice(index, 1, new_details);
                      set_value(JSON.stringify(new_obj));
                    }}
                    minRows={1}
                    className={`rounded shadow border resize-none  focus:shadow-outline text-xl w-full italic
                              text-white placeholder-gray-500 bg-gray-800`}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor={`${type}-alt-${index}`}>
                    Alternate text for accessibility
                  </label>
                  <TextareaAutosize
                    name={`${type}-alt-${index}`}
                    id={`${type}-alt-${index}`}
                    value={hasData(value[index]?.alt) ? value[index]?.alt : ""}
                    placeholder={"Enter text that describes your image content"}
                    onChange={(e) => {
                      let new_obj = value;
                      let newval = e.target.value;
                      let old_details = value[index];

                      let new_details = { ...old_details, alt: newval };

                      //replace index with new index contents if index exists
                      new_obj.splice(index, 1, new_details);
                      set_value(JSON.stringify(new_obj));
                    }}
                    minRows={1}
                    className={`rounded shadow border resize-none  focus:shadow-outline text-xl w-full italic
                              text-white placeholder-gray-500 bg-gray-800`}
                  />
                </div>
              </div>
            )}

            {["audio", "mic"].indexOf(type) > -1 && (
              <>
                {/* <audio
                    controls
                    src={value[index]?.url}
                    className='w-full'
                /> */}
                {!file_loaded && (
                  <div className="text-center flex items-center content-center justify-center my-2">
                    <div
                      onClick={() => set_file_loaded(true)}
                      className="px-4 py-2 text-lg bg-gray-500 rounded-md "
                    >
                      Load audio
                    </div>
                  </div>
                )}
                {file_loaded && ReactPlayer.canPlay(value[index]?.url) && (
                  <>
                    <ReactPlayer
                      url={srcSafe(value[index]?.url)}
                      width="100%"
                      height="50px"
                      controls={true}
                      onReady={() => {
                        //console.log('loaded')
                        set_file_loaded(true);
                      }}
                      onError={(e) => {
                        console.log("error", e);
                        set_file_loaded(false);
                        if (file_loaded_attempts <= 5) {
                          setTimeout(() => {
                            set_file_loaded(true);
                            set_file_loaded_attempts((prev) => prev + 1);
                          }, 1000);
                        }
                      }}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const [isAdmin, set_isAdmin] = useState(false);

  //LOOKUP DATA
  const {
    data: single_id,
    loading: iddataisLoading,
    error: iddataisError,
  } = useSWRWrapper(
    type == "user" ? value : null, //id
    `/api/public/v0/user/select-name?name=${value}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  useEffect(() => {
    let varIsAdmin = isAdminUser(
      single_id?.user_id, //user_id
      single_id?.bol_staff_help, //bol_staff_help
      single_id?.staff_json, //staff_json
      single_id?.bol_admin_help, //bol_admin_help
      userdata["id"]?.toString(), //my_id
      userdata["bol_admin"]?.toString(), //global_admin
      tablehelptype("broadcast")
    );
    set_isAdmin(varIsAdmin);
    if (varIsAdmin) set_hidden_value(single_id?.user_id);
  }, [single_id, userdata, tablehelptype, isAdminUser, set_hidden_value]);

  // ONLY SHOW ALLOWED CONDITIONS
  if (!show_condition) {
    return <></>;
  }

  if (type == "user") {
    return (
      <>
        <div className="relative">
          <div
            className={`font-normal text-sm absolute z-100 inset-y-0 right-0 pr-3 flex items-center leading-5
                  ${
                    single_id?.user_id?.toString() == "0" || !isAdmin
                      ? "text-red-500"
                      : "text-green-500"
                  }
                  `}
            data-tip={
              single_id?.user_id?.toString() == "0"
                ? "Not found"
                : !isAdmin
                ? "User does not have staff help enabled"
                : "Success!"
            }
            data-for={`inputform`}
          >
            <SvgJsx
              type={"fill"}
              icon={
                single_id?.user_id?.toString() == "0"
                  ? "exclamation-circle-sm"
                  : !isAdmin
                  ? "lock-closed-sm"
                  : "check-circle-sm"
              }
              className={` h-6 w-6 `}
              title={
                single_id?.user_id?.toString() == "0"
                  ? "Not found"
                  : !isAdmin
                  ? "User does not allow admin help"
                  : "Great success"
              }
            />
          </div>
          <input
            className={`rounded shadow border resize-none  focus:shadow-outline text-xl w-full relative
                  
                 placeholder-gray-500 bg-gray-800
                            ${
                              single_id?.user_id?.toString() == "0" || !isAdmin
                                ? ` border-red-500
                                  text-red-500 `
                                : `text-white `
                            }
                            `}
            // placeholder={placeholder}
            id={name}
            name={name}
            value={value}
            onChange={(e) => {
              set_value(e.target.value);
              set_changes(true);
            }}
            type="text"
            disabled={submitting}
          />
        </div>
        {isMounted && (
          <ReactTooltip
            id={`inputform`}
            place={`bottom`}
            effect="solid"
            clickable={false}
            delayHide={200}
            delayUpdate={0}
            className="reacttooltip rounded-3xl shadow-lg"
          />
        )}
      </>
    );
  }

  {
    /* TOGGLE */
  }
  if (type == "toggle") {
    return (
      <>
        <div className="my-4">
          <div className="flex-1 flex items-center content-center h-8">
            <div className="flex-0 flex mt-1 pr-2">
              <ToggleButton
                bol_item={value}
                toggle_text={title}
                size={"large"}
                clickEvent={() => {
                  let vtoggle = bumber(value);
                  set_value(vtoggle);
                }}
              />
            </div>
            <div className="flex-1">
              <label htmlFor={name}>
                <div className="flex items-center content-center">
                  <div className="flex-0">
                    <h3 className="font-bold">{title}</h3>
                  </div>
                  {helptip?.length > 0 && (
                    <div className="flex-0 ml-2">
                      <HelpTip id={name} tip={helptip} />
                    </div>
                  )}
                </div>
                <div className="italic text-sm">{description}</div>
              </label>
            </div>
          </div>
        </div>
      </>
    );
  }

  {
    /* COLOR PICKER */
  }
  if (type == "color") {
    return (
      <>
        <div className="my-4 cursor-pointer">
          <div className="flex-1 flex items-center content-center h-8 cursor-pointer">
            <div className="flex-0 flex mt-1 pr-2">
              <input
                type="color"
                className={`w-10 h-10 resize-none border border-gray-700

                                  `}
                // placeholder={placeholder}
                id={name}
                name={name}
                value={value}
                onChange={(e) => {
                  set_value(e.target.value);
                  set_changes(true);
                }}
                disabled={submitting}
              />
            </div>
            <div className="flex-1">
              <label htmlFor={name}>
                <div className="flex items-center content-center">
                  <div className="flex-0">
                    <h3 className="font-bold">{title} </h3>
                  </div>
                  {helptip?.length > 0 && (
                    <div className="flex-0 ml-2">
                      <HelpTip id={name} tip={helptip} />
                    </div>
                  )}
                </div>
                <div className="italic text-sm">{description}</div>
              </label>
            </div>
          </div>
        </div>
      </>
    );
  }

  //ALL OTHER INPUT TYPES
  return (
    <>
      <div className="my-4">
        {helptip?.length > 0 && <HelpTip id={name} tip={helptip} />}

        {["mic", "audio", "image", "avatarupload"].indexOf(type) <= -1 && label}

        {/* TEXT INPUT */}
        {type == "text" && (
          <>
            <input
              className={`rounded shadow border outline-1 resize-none text-xl w-full 
                      ${
                        single_id?.user_id?.toString() == "0" || !isAdmin
                          ? `border-red-500 focus:border-red-500 focus:outline-red-500`
                          : ``
                      }
                        
                         text-white placeholder-gray-500 bg-gray-800

                          `}
              // placeholder={placeholder}
              id={name}
              name={name}
              value={value}
              onChange={(e) => {
                set_value(e.target.value);
                set_changes(true);
              }}
              type="text"
              disabled={submitting}
              maxLength={meta?.maxChars > 0 ? meta?.maxChars : 524288}
              autoFocus={meta?.autoFocus}
            />
          </>
        )}

        {/* TEXTAREA AUTOSIZE */}
        {type == "textarea-autosize" && (
          <TextareaAutosize
            className={`rounded shadow border resize-none  focus:shadow-outline text-xl w-full
                          text-white placeholder-gray-500 bg-gray-800

                          `}
            // placeholder={placeholder}
            id={name}
            name={name}
            value={value}
            onChange={(e) => {
              set_value(e.target.value);
              set_changes(true);
            }}
            disabled={submitting}
            minRows={hasData(meta?.minRows) ? meta?.minRows : 4}
          />
        )}

        {/* WYSIWYG */}
        {/* {type=='wysiwyg' &&
              // <Editor 
              //   editorState={editorState} 
              //   onChange={setEditorState} 
              // />
              } */}

        {/* SELECT */}
        {type == "select" && (
          <select
            className={`rounded shadow border resize-none  cursor-pointer focus:shadow-outline text-xl w-full
                          text-white placeholder-gray-500 bg-gray-800

                          `}
            // placeholder={placeholder}
            id={name}
            name={name}
            defaultValue={value}
            onChange={(e) => {
              set_value(e.target.value);
              set_changes(true);
            }}
            disabled={submitting}
          >
            {options?.map((e, index) => (
              <option key={index} value={hasData(e?.value) ? e?.value : e}>
                {hasData(e?.text) ? e?.text : e}
              </option>
            ))}
          </select>
        )}

        {/* SELECT BOX */}
        {type == "selectbox" && (
          <div className="mt-1">
            {options?.map((e, i) => (
              <div
                key={i}
                className={`w-32 h-10 text-center mr-2 mb-2 font-bold cursor-pointer inline-flex items-center content-center border rounded-md
                        ${
                          value == e?.value
                            ? "border-blue-500 bg-blue-500 hover:bg-blue-400 text-white"
                            : "border-gray-700  hover:border-gray-400 text-gray-400 hover:text-white "
                        }
                        `}
                onClick={async () => {
                  if (JSON.parse(hidden_value)?.length > 0) {
                    const confirmed = await isConfirmed(
                      "Changing your post will reset all your attachments. Are you sure?"
                    );
                    // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
                    if (confirmed) {
                      set_hidden_value("[]");
                      set_value(hasData(e?.value) ? e?.value : e);
                    }
                  } else {
                    set_value(hasData(e?.value) ? e?.value : e);
                  }
                }}
              >
                <div className="mx-auto">{hasData(e?.text) ? e?.text : e}</div>
              </div>
            ))}
          </div>
        )}

        {/* SELECT OPTIONS */}
        {type == "selectoptions" && (
          <div className="mt-1">
            {options?.map((e, i) => (
              <div key={i}>
                <SelectListItem
                  set_selected_value={() => {
                    set_value(hasData(e?.value) ? e?.value : e);
                    set_changes(true);
                  }}
                  selected_value={value}
                  value={e?.value}
                  text={<>{hasData(e?.text) ? e?.text : e}</>}
                  premium_level={
                    hasData(e?.premium_level) ? e?.premium_level : 0
                  }
                  userdata={e?.userdata}
                  recommended={e?.recommended}
                />
              </div>
            ))}

            {meta?.triggerArray?.indexOf(value?.toString()) > -1 && (
              <div className="py-5">
                <div className=" text-white bg-red-700 border border-red-900 px-4 py-2 rounded-md">
                  {
                    meta?.triggerText[
                      meta?.triggerArray?.indexOf(value?.toString())
                    ]
                  }
                </div>
              </div>
            )}
          </div>
        )}

        {/* DATE */}
        {type == "date" && (
          <div className="sm:flex items-center content-center">
            <div className="flex-0">
              <input
                className={`rounded shadow border outline-1 resize-none text-xl w-full cursor-pointer
                          ${
                            single_id?.user_id?.toString() == "0" || !isAdmin
                              ? `border-red-500 focus:border-red-500 focus:outline-red-500`
                              : ``
                          }
                            
                              text-white placeholder-gray-500 bg-gray-800

                              `}
                placeholder={placeholder}
                id={name}
                name={name}
                value={edit_date}
                // onFocus={(e)=>{
                //   console.log("focus",e.target.value,edit_date)
                //   set_edit_date(e.target.value)
                //  // alert(edit_date)
                //  // set_edit_date(domInputStringifyDateObject(edit_date))
                // }}
                onBlur={(e) => {
                  try {
                    let newvalue = extractDateObject(
                      new Date(
                        hasData(e.target.value) ? e.target.value : ""
                      )?.toISOString()
                    );

                    set_value({
                      ...value,
                      year: newvalue?.year,
                      month: newvalue?.month,
                      date: newvalue?.date,
                      second: "00",
                      millisecond: "000",
                    });
                    set_changes(true);
                  } catch (e) {
                    console.log("oopsie", e);
                  }
                }}
                onChange={(e) => {
                  set_edit_date(e.target.value);
                }}
                type="date"
                disabled={submitting}
              />
            </div>
            <div className="flex-0 flex items-center content-center">
              <div className="flex-0">
                <select
                  className={`rounded shadow border resize-none cursor-pointer focus:shadow-outline text-xl w-full
                                text-white placeholder-gray-500 bg-gray-800
                                `}
                  // placeholder={placeholder}
                  id={name}
                  name={name}
                  value={
                    parseInt(value?.hour) > 12
                      ? padZeroVariable(
                          (parseInt(value?.hour) - 12)?.toString(),
                          2,
                          "0"
                        )
                      : padZeroVariable(value?.hour?.toString(), 2, "0")
                  }
                  onChange={(e) => {
                    set_value((prev) => {
                      return {
                        ...prev,
                        hour:
                          prev?.meridian == "AM"
                            ? e.target.value
                            : (parseInt(e?.target.value) + 12)?.toString(),
                        second: "00",
                        millisecond: "000",
                      };
                    });
                    set_changes(true);
                  }}
                  disabled={submitting}
                >
                  <option key={index} value={"12"}>
                    {12}
                  </option>
                  {[...Array(11).keys()]?.map((e, index) => (
                    <option key={index} value={padZeroVariable(e + 1, 2, "0")}>
                      {padZeroVariable(e + 1, 2, "0")}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-0">
                <select
                  className={`rounded shadow border resize-none  cursor-pointer focus:shadow-outline text-xl w-full
                                text-white placeholder-gray-500 bg-gray-800
                                `}
                  // placeholder={placeholder}
                  id={name}
                  name={name}
                  value={value?.minute?.toString()}
                  onChange={(e) => {
                    set_value({
                      ...value,
                      minute: e.target.value,
                      second: "00",
                      millisecond: "000",
                    });
                    set_changes(true);
                  }}
                  disabled={submitting}
                >
                  {[...Array(60).keys()]?.map((e, index) => (
                    <option key={index} value={padZeroVariable(e, 2, "0")}>
                      {padZeroVariable(e, 2, "0")}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-0">
                <select
                  className={`rounded shadow border resize-none  cursor-pointer focus:shadow-outline text-xl w-full
                                text-white placeholder-gray-500 bg-gray-800
                                `}
                  // placeholder={placeholder}
                  id={name}
                  name={name}
                  value={value?.meridian?.toString()}
                  onChange={(e) => {
                    set_value((prev) => {
                      return {
                        ...value,
                        meridian: e.target.value,
                        hour:
                          e.target.value == "AM"
                            ? parseInt(prev?.hour) <= 12
                              ? prev?.hour?.toString()
                              : (parseInt(prev?.hour) - 12)?.toString()
                            : parseInt(prev?.hour) > 12 //PM
                            ? prev?.hour?.toString()
                            : (parseInt(prev?.hour) + 12)?.toString(),
                        second: "00",
                        millisecond: "000",
                      };
                    });
                    set_changes(true);
                  }}
                  disabled={submitting}
                >
                  {["AM", "PM"]?.map((e, index) => (
                    <option key={index} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {/* TIMEZONE  */}
        {type == "timezone" && (
          <div className="flex items-center content-center">
            <div className="flex-0 flex items-center content-center">
              <select
                className={`rounded shadow border resize-none  cursor-pointer focus:shadow-outline text-xl w-full
                                text-white placeholder-gray-500 bg-gray-800
                                `}
                // placeholder={placeholder}
                id={name}
                name={name}
                value={value}
                onChange={(e) => {
                  set_value(e.target.value);
                  set_changes(true);
                }}
                disabled={submitting}
              >
                {moment.tz.names()?.map((e, index) => (
                  <option key={index} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-xl font-bold ml-4">
              {moment.tz(hidden_value, value).format("z")}
            </div>
          </div>
        )}

        {/* TIME */}
        {type == "time" && (
          <div className="flex items-center content-center">
            <div className="flex-0">
              <select
                className={`rounded shadow border resize-none cursor-pointer focus:shadow-outline text-xl w-full
                              text-white placeholder-gray-500 bg-gray-800
                              `}
                // placeholder={placeholder}
                id={name}
                name={name}
                value={value?.hours?.toString()}
                onChange={(e) => {
                  set_value({ ...value, hours: e.target.value });
                  set_changes(true);
                }}
                disabled={submitting}
              >
                {time.hours?.map((e, index) => (
                  <option key={index} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-0">
              <select
                className={`rounded shadow border resize-none  cursor-pointer focus:shadow-outline text-xl w-full
                              text-white placeholder-gray-500 bg-gray-800
                              `}
                // placeholder={placeholder}
                id={name}
                name={name}
                value={value?.minutes?.toString()}
                onChange={(e) => {
                  set_value({ ...value, minutes: e.target.value });
                  set_changes(true);
                }}
                disabled={submitting}
              >
                {time.minutes?.map((e, index) => (
                  <option key={index} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
