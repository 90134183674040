import { PromptContext } from "@/components/context/prompt-context";
import { useContext, useEffect, useState } from "react";
import { hasData, hasNoData } from "../hasData";

//From this tutorial:
//https://medium.com/@jaredloson/a-replacement-for-window-prompt-using-promises-and-react-hooks-cfc011e76a7a

export const usePrompt = () => {
  const [inputvalue, set_inputvalue] = useState(null);

  const props_defaults = {
    title: <>Are you sure?</>,
    body: <></>,
    inputvalue: inputvalue,
    set_inputvalue: set_inputvalue,
    confirm: <>OK</>,
    cancel: <>Cancel</>,
    show_input: false,
    show_title: true,
    show_confirm: true,
    show_cancel: true,
    onSubmit: () => null,
  };

  const [prompt, setPrompt] = useContext(PromptContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isPrompted = (props) => {
    setNeedsCleanup(true);

    const promise = new Promise((resolve, reject) => {
      setPrompt({
        props:
          typeof props === "string" || props instanceof String
            ? {
                title: hasData(props) ? props : props_defaults.title,
                body: props_defaults.body,
                inputvalue: props_defaults.inputvalue,
                set_inputvalue: props_defaults.set_inputvalue,
                confirm: props_defaults.confirm,
                cancel: props_defaults.cancel,
                show_input: props_defaults.show_input,
                show_title: props_defaults.show_title,
                show_confirm: props_defaults.show_confirm,
                show_cancel: props_defaults.show_cancel,
                onSubmit: props_defaults.onSubmit,
              }
            : {
                title: hasNoData(props?.title, props_defaults.title),
                body: hasNoData(props?.body, props_defaults.body),
                inputvalue: hasNoData(
                  props?.inputvalue,
                  props_defaults.inputvalue
                ),
                set_inputvalue: hasNoData(
                  props?.set_inputvalue,
                  props_defaults.set_inputvalue
                ),
                confirm: hasNoData(props?.confirm, props_defaults.confirm),
                cancel: hasNoData(props?.cancel, props_defaults.cancel),
                show_input: props.show_input
                  ? props?.show_input
                  : props_defaults.show_input,
                show_title: hasNoData(
                  props?.show_title,
                  props_defaults.show_title
                ),
                show_confirm: hasNoData(
                  props?.show_confirm,
                  props_defaults.show_confirm
                ),
                show_cancel: hasNoData(
                  props?.show_cancel,
                  props_defaults.show_cancel
                ),
                onSubmit: props.show_input
                  ? props?.onSubmit
                  : props_defaults.show_input,
              },
        isPromptOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });
    return promise.then(
      () => {
        setPrompt({ ...props, isPromptOpen: false });
        return true;
      },
      () => {
        setPrompt({ ...props, isPromptOpen: false });
        return false;
      }
    );
  };

  useEffect(() => {
    return () => {
      if (prompt.cancel && needsCleanup) {
        prompt.cancel();
      }
    };
  }, [prompt, needsCleanup]);

  return {
    ...prompt,
    isPrompted,
  };
};
