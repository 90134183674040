import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SelectListItem from "../select-list-item";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { config } from "@/lib/config";
import FormInput from "../form-input/form-input";
import SvgJsx from "../svg/svg-jsx";

export const ModalWizardOwner = ({
  isOpen,
  closeModal,
  user_id,
  set_user_id,
  original_owner_id,
  original_owner_name,
  admin_user_name,
  set_admin_user_name,
  userdata,
  submitting,
  set_submitting,
  processing,
  set_processing,
  changes,
  set_changes,
  staffdata_active,
  set_staffdata_active,
  isAdmin,
}) => {
  const selectRef = useRef(null);

  //LOOKUP DATA
  const {
    data: staffdata,
    loading: staffdataisLoading,
    error: staffdataisError,
  } = useSWRWrapper(
    userdata?.id, //id
    `/api/private/user/select-by-staff`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  useEffect(() => {
    if (staffdata && !staffdataisLoading && !staffdataisError) {
      staffdata.unshift({
        user_name: original_owner_name,
        id: original_owner_id,
        permissions: 2047,
      });

      set_staffdata_active(staffdata);
    }
  }, [staffdata, staffdataisLoading, staffdataisError]);

  //LOOKUP DATA
  const {
    data: single_id,
    loading: iddataisLoading,
    error: iddataisError,
  } = useSWRWrapper(
    admin_user_name, //id
    `/api/public/v0/user/select-name?name=${admin_user_name}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          open={isOpen}
          className="fixed inset-0 z-[100] overflow-y-scroll    bg-[rgb(0,0,0,0.85)]"
          onClose={() => closeModal()}
        >
          <div className="min-h-screen px-5 max-w-xl mx-auto text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full mb-10 border transform pb-1 mt-[48px] overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                        bg-gray-900  border-gray-700"
              >
                {/* USERS */}

                <div className="w-full content-center item-center flex py-2 px-4 shadow-md  bg-gray-700 border-b  border-gray-600">
                  <div className="flex-1 font-bold text-xl">
                    Set an owner name
                  </div>

                  <div className="flex-0">
                    <button
                      type="button"
                      tabIndex={0}
                      className="mt-2"
                      onClick={closeModal}
                    >
                      <SvgJsx
                        type={`fill`}
                        icon={`x-sm`}
                        className={` w-5 h-5 flex-0 my-auto `}
                        title={`Close`}
                      />
                    </button>
                  </div>
                </div>

                <div className=" w-full  overflow-y-auto content-center item-center flex px-2">
                  <div className="flex-1 py-2">
                    <select
                      ref={selectRef}
                      className="bg-gray-700 w-full"
                      defaultValue={user_id}
                      onChange={(e) => set_user_id(e.target.value)}
                    >
                      {staffdata_active?.map((e, i) => {
                        return (
                          <option value={e?.id} key={i}>
                            {e?.user_name}{" "}
                            {e?.id?.toString() == original_owner_id?.toString()
                              ? "(current)"
                              : ""}
                          </option>
                        );
                      })}
                      {staffdata_active
                        .map((f) => f?.id?.toString())
                        .indexOf(user_id?.toString()) == -1 && (
                        <option value={user_id}>{admin_user_name}</option>
                      )}
                      {isAdmin && (
                        <option value={"-1"} className="text-gray-400">
                          Manually enter a name
                        </option>
                      )}
                    </select>

                    {isAdmin && selectRef?.current?.value == "-1" && (
                      <FormInput
                        type={"user"}
                        title={"Owner"}
                        description={""}
                        helptip={""}
                        name={"admin_user_name"}
                        placeholder={"Username"}
                        value={admin_user_name}
                        set_value={set_admin_user_name}
                        hidden_value={user_id}
                        set_hidden_value={set_user_id}
                        //submitting
                        submitting={submitting}
                        set_submitting={set_submitting}
                        processing={processing}
                        set_processing={set_processing}
                        set_changes={set_changes}
                        changes={changes}
                      />
                    )}
                  </div>
                </div>

                <div
                  className="
                            "
                >
                  <button
                    type="button"
                    tabIndex={0}
                    className="mt-2 bg-blue-600 text-white hover:bg-blue-500 font-bold px-4 py-2 mx-2 mb-2 rounded-md"
                    onClick={closeModal}
                  >
                    Set and Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
