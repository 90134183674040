import { validJson } from "@/lib/utils/isJsonValid";

export const handleGenerate = async (prompt, doBefore, doAfter) => {
  await doBefore();
  const response = await fetch(
    "/api/private/upload/url?update=chat&type=image&origin=ai",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt,
      }),
    }
  );
  const imageResponse = await response.json();
  // console.log(imageResponse);
  // {
  //   outcome: 1,
  //   outputURL_original: outputURL_original,
  //   thumbs: metadata_json?.thumbs,
  //   url: s3_destination_url,
  //   hash: hash,
  //   size: file_size,
  //   crdate: crdate,
  //   source: upload_service_type,
  //   filename: `${hash}.${extention}`,
  //   filename_original: file_name,
  //   extention: extention,
  //   item_id: item_id,
  //   metadata_json: JSON.stringify(metadata_json),
  //   exists: 1,
  //   => width: imageResponse?.metadata_json?.width,
  //   => height: imageResponse?.metadata_json?.height,
  //   => description: imageResponse?.metadata_json?.description,
  // }
  await doAfter({
    ...imageResponse,
    width: validJson(imageResponse?.metadata_json)?.width,
    height: validJson(imageResponse?.metadata_json)?.height,
  });
  return;
};
