import React from "react";
import { textProcess } from "@/lib/utils/textprocess";
import Image from "next/image";
import { hasData } from "@/lib/utils/hasData";
import { signIn } from "next-auth/client";
export default function HeroCTA({ ssr_data_tribe }) {
  return (
    <>
      <div
        onClick={() => signIn()}
        className={` overflow-hidden  bg-gray-950 hover:bg-gray-900 cursor-pointer border-b

                        border-gray-700
                          `}
      >
        <div className="relative mx-auto px-4 sm:px-6 xl:px-8">
          <div className="flex items-center content-center ">
            {hasData(ssr_data_tribe?.marketing_hero_image_url) && (
              <div className="flex-0 hidden xs:block mr-4  ">
                <Image
                  src={ssr_data_tribe?.marketing_hero_image_url}
                  alt={ssr_data_tribe?.marketing_seo_category}
                  width={88}
                  height={88}
                  className={`rounded-2xl`}
                />
              </div>
            )}

            <div className="flex-1  sm:text-left my-5">
              <h1
                className={`text-xl sm:text-2xl leading-8 font-extrabold tracking-tight text-left
                                           text-gray-100 
                          `}
              >
                {textProcess(ssr_data_tribe?.marketing_headline)}
              </h1>

              <p
                className="mx-auto text-base sm:text-lg text-left  mt-2
                                       text-gray-300
                        "
              >
                {textProcess(ssr_data_tribe?.marketing_description)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
