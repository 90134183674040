import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import PopupMenuOptionLink from "../popup-menu-option-link";
import MenuButton from "./menu-button";
import { signIn } from "next-auth/client";
import { TribeContext } from "@/components/context/tribe-context";
import { textProcess } from "@/lib/utils/textprocess";
import { getBitwise } from "@/lib/utils/bitoperations";
import { USER_PERMS } from "@/lib/config";
import AvatarFlairSizes from "../user/avatar-flair-sizes";

function MenuAccountDropdown({
  loading,
  session,
  userdata,
  isUserDataLoading,
  isUserDataError,
  handleLogoutClick,
  navtype,
  open,
  set_open,
}) {
  const [isMounted, set_isMounted] = useState(false);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  // const ref = useRef(null);
  // const closeMenu = () => {if (ref.current) ref.current.close()};

  const PopupTrigger = (
    <div tabIndex={0} role="button" onClick={() => set_open("account")}>
      <div>
        <div
          className={`flex flex-none items-center content-center cursor-pointer whitespace-nowrap border group  p-0.5 rounded-md text-sm font-medium 
          
          text-white   hover:text-white
        border-transparent
          `}
          id="main-menu"
          aria-haspopup="true"
        >
          <AvatarFlairSizes
            isPlaying={false}
            isContributor={userdata?.contributor_level > 0}
            avatar_url={userdata?.avatar_url}
            avatar_url_sfw={userdata?.avatar_url_sfw}
            bol_nsfw={userdata?.bol_nsfw}
            avatar_crdate={userdata?.avatar_crdate}
            id={userdata?.id}
            user_name={userdata?.user_name}
            size={"tiny"}
          />
        </div>
      </div>
    </div>
  );

  if (!session) {
    return <MenuButton />;
  }

  return (
    <div className="menu flex items-center content-center  flex-0 z-50">
      {!isMounted || (!session && <>{PopupTrigger}</>)}

      {isMounted && session && (
        <>
          <Popup
            position="bottom left"
            open={open}
            onOpen={() => set_open("account")}
            //onClose={()=>set_open('')}
            trigger={PopupTrigger}
            //on="click"
            closeOnDocumentClick
            closeOnEscape
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{
              padding: "0px",
              border: "none",
              backgroundColor: "transparent",
              zIndex: 1999,
            }}
            arrow={false}
            nested
            //ref={isMounted ? ref : null}
          >
            <div className="menu ring-0 fixed top-[47px] right-[0px] w-full xs:w-64 ">
              <div
                className="menu flex flex-col gap-y-1 rounded-b-md shadow-md p-2 border-x border-b
                                        bg-gray-800 border-gray-700"
              >
                <div
                  className="flex items-center content-center px-1 pb-1 rounded-b-md font-bold text-md
                                bg-gray-800"
                >
                  <div className="flex-1 text-sm">
                    {session &&
                      !isUserDataLoading &&
                      `${textProcess(`Hi`)} ${userdata?.user_name}!`}
                    {(loading || isUserDataLoading) &&
                      textProcess(`Just a sec!`)}
                    {!loading && !session && textProcess(`Join us!`)}
                  </div>
                  {/* <div className="flex-0">
                                {avatar}
                              </div> */}
                </div>

                {(loading || isUserDataLoading) && (
                  <>
                    <div className="p-4">{textProcess(`Still Loading...`)}</div>
                  </>
                )}

                {!(loading || isUserDataLoading) && session && (
                  <AccountOptions
                    userdata={userdata}
                    navtype={navtype}
                    handleLogoutClick={handleLogoutClick}
                  />
                )}

                {!(loading || isUserDataLoading) && !session && (
                  <NoAccountOptions user_name={"there"} navtype={navtype} />
                )}
              </div>
            </div>
          </Popup>
        </>
      )}
    </div>
  );
}

function AccountOptions({ userdata, navtype, handleLogoutClick }) {
  const ssr_data_tribe = useContext(TribeContext);

  // <MenuOption
  //     onClick={() => {
  //       if (
  //         getBitwise(
  //           userdata?.credentials_bitarray,
  //           Object.keys(USER_PERMS)
  //         )["post"]
  //       ) {
  //         openModal(0, "-1"); //post chooser
  //         set_open("");
  //       } else {
  //         alert(
  //           `Your account does not have access to this feature yet. Ask an admin or email ${ssr_data_tribe?.email_support} for beta access.`
  //         );
  //       }
  //     }}
  //     icon={"newspaper-sm"}
  //     title={textProcess("Create a Post")}
  //     userdata={userdata}
  //     type={"post"}
  //   />

  return (
    <>
      <PopupMenuOptionLink
        icon={"newspaper-sm"}
        iconclass={"group-hover:animate-wiggle"}
        name={"My Profile"}
        type={"profile"}
        url={`/${userdata?.user_name?.toLowerCase()}`}
        behavior={"link"}
        navtype={navtype}
      />

      {/* CHAT */}
      {userdata?.bol_creator?.toString() == "1" && (
        <PopupMenuOptionLink
          icon={"chat-alt-sm"}
          iconclass={"group-hover:animate-wiggle"}
          name={"My Chatroom"}
          type={"chat"}
          url={`/${userdata?.user_name?.toLowerCase()}/chat`}
          behavior={"link"}
          navtype={navtype}
        />
      )}

      {/* SOUNDBOARD */}
      {userdata?.bol_creator?.toString() == "1" &&
        getBitwise(userdata?.credentials_bitarray, Object.keys(USER_PERMS))[
          "soundboard"
        ] && (
          <PopupMenuOptionLink
            icon={"grid-sm"}
            iconclass={"group-hover:animate-wiggle"}
            name={"My Soundboards"}
            type={"soundboard"}
            url={`/${userdata?.user_name?.toLowerCase()}/soundboards`}
            behavior={"link"}
            navtype={navtype}
          />
        )}

      {/* SETTINGS */}
      <PopupMenuOptionLink
        icon={"settings-sm"}
        iconclass={"group-hover:animate-spin"}
        name={"My Settings"}
        type={"settings"}
        url={`/${userdata?.user_name?.toLowerCase()}/settings`}
        behavior={"link"}
        navtype={navtype}
      />

      {/* BROADCAST SETTINGS */}
      {userdata?.bol_creator?.toString() == "1" &&
        getBitwise(userdata?.credentials_bitarray, Object.keys(USER_PERMS))[
          "broadcastlive"
        ] && (
          <PopupMenuOptionLink
            icon={"chart-bar-sm"}
            iconclass={"group-hover:animate-wiggle"}
            name={"Broadcast Stats"}
            type={"settings"}
            url={`/${userdata?.user_name?.toLowerCase()}/settings/broadcast`}
            behavior={"link"}
            navtype={navtype}
          />
        )}

      {ssr_data_tribe?.bol_contribute == "1" && (
        <PopupMenuOptionLink
          icon={"heart-sm"}
          iconclass={
            "text-red-400 group-hover:text-red-600 group-hover:animate-wiggle"
          }
          name={"Contribute"}
          type={"contribute"}
          url={"/contribute"}
          behavior={"link"}
          navtype={navtype}
        />
      )}
      <PopupMenuOptionLink
        onClick={() => handleLogoutClick("menu")}
        icon={"logout-sm"}
        iconclass={"w-5 h-5"}
        name={"Logout"}
        type={"logout"}
        url={""}
        navtype={navtype}
        behavior={"button"}
      />
    </>
  );
}

function NoAccountOptions({ user_name, navtype }) {
  return (
    <>
      <PopupMenuOptionLink
        onClick={() => {}}
        icon={"profile-sm"}
        iconclass={"w-5 h-5"}
        name={"Create an account"}
        type={"register"}
        url={"/app/auth/register"}
        navtype={navtype}
        behavior={"link"}
      />
      <PopupMenuOptionLink
        onClick={signIn}
        icon={"profile-sm"}
        iconclass={"w-5 h-5"}
        name={"Login to an account"}
        type={"login"}
        url={""}
        navtype={navtype}
        behavior={"button"}
      />
    </>
  );
}

export default MenuAccountDropdown;
