import { useContext, useState } from "react";
import { signOut, useSession } from "next-auth/client";
import * as React from "react";
import { getRemoveUserCookies } from "@/lib/utils/getRemoveUserCookies";
import toast from "react-simple-toasts";
import { toastConfig } from "react-simple-toasts";
import { PlayerContext } from "@/components/context/player-context";
import { PlayerNavLive } from "@/components/player/player-nav-live";
import PlayerNavFile from "@/components/player/player-nav-file";
import MenuLogoDropdown from "./menu-logo-dropdown";
import MenuAccountDropdown from "./menu-account-dropdown";
import MenuCreateDropdown from "./menu-create-dropdown";
import HeroCTA from "@/components/content/hero-cta";

export default function NavPrimary({
  ssr_data_tribe,
  userdata,
  isUserDataLoading,
  isUserDataError,
  navtype,
  fixed = false,
  fullscreen_children = false,
}) {
  const { player_state, set_player_state } = useContext(PlayerContext);

  const [menuaccount_open, set_menuaccount_open] = useState(false);
  const [menulogo_open, set_menulogo_open] = useState(false);
  const [menucreate_open, set_menucreate_open] = useState(false);
  const [menuplayer_open, set_menuplayer_open] = useState(false);

  const chooseNavmenu = (selected) => {
    if (selected == "account") {
      set_menuaccount_open(true);
      set_menulogo_open(false);
      set_menucreate_open(false);
      set_menuplayer_open(false);
    }
    if (selected == "logo") {
      set_menuaccount_open(false);
      set_menulogo_open(true);
      set_menucreate_open(false);
      set_menuplayer_open(false);
    }
    if (selected == "create") {
      set_menuaccount_open(false);
      set_menulogo_open(false);
      set_menucreate_open(true);
      set_menuplayer_open(false);
    }
    if (selected == "player") {
      set_menuaccount_open(false);
      set_menulogo_open(false);
      set_menucreate_open(false);
      set_menuplayer_open(true);
    }
    if (selected == "") {
      set_menuaccount_open(false);
      set_menulogo_open(false);
      set_menucreate_open(false);
      set_menuplayer_open(false);
    }
  };

  // const [player_active,set_player_active] = useState(player_state.file)
  const [session, loading] = useSession();
  // const [ isMounted, set_isMounted ] = useState<boolean>()

  // useEffect(() => {
  //   set_isMounted(true)
  // }, [])

  function handleLogoutClick(e) {
    getRemoveUserCookies();
    localStorage.clear();
    toastConfig({
      time: 2000,
      className: "",
      position: "right",
    });
    toast("You logged out", {
      time: 2000,
      className: "",
      clickable: true,
      clickClosable: false,
    });

    setTimeout(
      (e) =>
        signOut({
          callbackUrl: `${ssr_data_tribe?.server_website}/en/signout?x=${e}&id=${userdata?.id}`,
        }),
      1000
    );
  }

  return (
    <>
      <div
        className={` h-12 ${
          fixed ? "fixed z-[1000]" : "sticky z-[100] "
        } top-0 left-0 right-0  flex items-center content-center
                      mx-auto py-1 pl-1 pr-2 border-b shadow-md
                       border-gray-700 bg-gray-800
                      `}
      >
        <nav
          className="flex items-center content-center w-full"
          aria-label="Global"
        >
          <div className={` flex-0 flex-shrink-0 `}>
            <MenuLogoDropdown
              ssr_data_tribe={ssr_data_tribe}
              player_state={player_state}
              navtype={navtype}
              open={menulogo_open}
              set_open={chooseNavmenu}
            />
          </div>

          <div className="flex-1 flex items-center content-center">
            {player_state?.player_type == "live" && (
              <PlayerNavLive
                open={menuplayer_open}
                set_open={chooseNavmenu}
                userdata={userdata}
              />
            )}

            {player_state?.player_type == "file" && (
              <PlayerNavFile open={menuplayer_open} set_open={chooseNavmenu} />
            )}
          </div>

          <div className="flex-0">
            <MenuCreateDropdown
              classColor={` border border-white hover:border-transparent hover:bg-red-500 text-white`}
              userdata={userdata}
              session={session}
              open={menucreate_open}
              set_open={chooseNavmenu}
            />
          </div>

          <div className="flex-0">
            <MenuAccountDropdown
              loading={loading}
              session={session}
              userdata={userdata}
              isUserDataLoading={isUserDataLoading}
              isUserDataError={isUserDataError}
              handleLogoutClick={handleLogoutClick}
              navtype={navtype}
              open={menuaccount_open}
              set_open={chooseNavmenu}
            />
          </div>
        </nav>
      </div>
      {(!userdata || userdata?.id?.toString() == "0") &&
        !fullscreen_children && <HeroCTA ssr_data_tribe={ssr_data_tribe} />}
    </>
  );
}
